<template>
    <div class="home">
        <div class="animate__wrapper">
            <Main
                class="animate animate_sect main-animate-section"
                :main="page_data.data?.main"
            />
            <Consulting
                class="animate animate_sect"
                :consulting="page_data.data?.consulting"
            />
            <Experience
                class="animate animate_sect"
                :experience="page_data.data?.experience"
            />
        </div>
        <!-- <MainEmployee :employee="page_data.data?.employee" /> -->
        <Proud :proud="page_data.data?.proud" />
        <Stages :stages="page_data.data?.stages" />
        <Services :services="page_data.data?.services" />
        <SectionOrder />
    </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { mapActions, mapState } from "vuex";
import Main from "@/components/home/Main.vue";
import Consulting from "@/components/home/Сonsulting.vue";
import Experience from "@/components/home/Experience.vue";
import SectionOrder from "@/components/UI/SectionOrder.vue";
import Proud from "@/components/home/Proud.vue";
// import MainEmployee from "@/components/home/MainEmployee.vue";
import Services from "@/components/home/Services.vue";
import Stages from "@/components/home/Stages.vue";
export default {
    name: "HomeView",
    components: {
        Main,
        Consulting,
        Experience,
        Proud,
        SectionOrder,
        // MainEmployee,
        Stages,
        Services,
    },
    data() {
        return {
            smoother: null,
        };
    },
    methods: {
        init() {
            gsap.registerPlugin(ScrollTrigger);
            let sections = gsap.utils.toArray(".animate");
            let animate_icon = document.querySelector(".main-decor");
            let animate_img = document.querySelector(".main-img");

            let mm = gsap.matchMedia();
            mm.add("(min-width: 1201px)", () => {
                gsap.to(sections, {
                    xPercent: -100 * (sections.length - 1),
                    ease: "sine.inOut",
                    scrollTrigger: {
                        trigger: ".animate__wrapper",
                        pin: true,
                        scrub: 1,
                        // snap: 1 / (sections.length - 1),
                        snap: false,
                        start: "0px",
                        endTrigger: ".animate__wrapper", // Добавляем endTrigger
                        end: "bottom bottom", // Указываем момент окончания анимации
                        end: () =>
                            "+=" +
                            document.querySelector(".animate").offsetWidth,
                    },
                });
                gsap.fromTo(
                    animate_icon,
                    {
                        xPercent: 0,
                        yPercent: 0,
                        opacity: 1,
                    },
                    {
                        xPercent: 80,
                        yPercent: -100,
                        opacity: 0,
                        ease: "sine.inOut",
                        scrollTrigger: {
                            trigger: sections[0],
                            //   pin: true,
                            scrub: 3,
                            snap: false,
                            start: "top top",
                            end: "bottom top", // Указываем момент окончания анимации
                            endTrigger: sections[2], // Добавляем endTrigger
                            end: () =>
                                "+=" +
                                document.querySelector(".animate").offsetWidth -
                                sections[2].offsetWidth,
                        },
                    }
                );
                gsap.to(animate_img, {
                    xPercent: -200,
                    ease: "sine.inOut",
                    scrollTrigger: {
                        trigger: animate_img,
                        // pin: true,
                        scrub: 3,
                        snap: false,
                        start: "top top",
                        end: "bottom top", // Указываем момент окончания анимации
                        endTrigger: sections[2], // Добавляем endTrigger
                        end: () =>
                            "+=" +
                            document.querySelector(".animate").offsetWidth,
                    },
                });
            });
        },
        ...mapActions({
            getPageData: "page/getPageData",
        }),
    },
    mounted() {
        gsap.registerPlugin(ScrollTrigger);
        this.init();
        window.addEventListener("resize", () => {
            scrollTrigger.refresh();
            this.init();
        });
    },
    computed: {
        ...mapState({
            page_data: (state) => state.page.page_data,
        }),
    },
    async created() {
        await this.getPageData("page/home");
    },
};
</script>
<style>
.animate_sect {
    position: relative;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
    /* min-width: calc(100vw + 5%); */
    flex: 0 0 auto;
}
.animate__wrapper {
    background-position: center center;
    background-attachment: scroll;
    /* background-image: url(../assets/img/gradient-bg.png); */
    background: linear-gradient(135deg, var(--grad-from) 0%, #88c1ca 100%);
    background-size: cover;
    background-repeat: no-repeat;
    overscroll-behavior: none;
    display: flex;
    flex-wrap: nowrap;
}

.main-animate-section {
    overflow: hidden;
}

@media screen and (max-width: 1200px) {
    .animate__wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .animate_sect {
        height: 100%;
    }
}
</style>
