<template>
  <section id="nalog_serices">
    <div class="container nalog_services__wrapper">
      <h2 class="nalog_services-title">{{ services?.title }}</h2>
      <div class="nalog_services-list">
        <NalogServicesRow v-for="item in services?.list" :card="item" />
      </div>
    </div>
  </section>
</template>
<script>
import NalogServicesRow from "@/components/UI/NalogServiceRow.vue";

export default {
  components: {
    NalogServicesRow,
  },
  props: {
    services: {
      title: "",
      list: [],
    },
  },
};
</script>
<style>
.container.nalog_services__wrapper {
  padding: 150px 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.nalog_services-title {
  text-align: center;
  color: var(--dark);
  font-size: 60px;
  font-family: JuraSemiBold;
  line-height: 1;
  font-weight: 600;
}
.nalog_services-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media screen and (max-width: 1200px) {
  .nalog_services-list {
    display: block;
    columns: 3 auto;
    column-gap: 20px;
    column-fill: balance;
  }

  .nalog_services-list > *:last-of-type {
    margin-bottom: 0;
  }
  .nalog_services-list > * {
    page-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: 20px;
    float: left;
  }
}
@media screen and (max-width: 750px) {
  .container.nalog_services__wrapper {
    padding: 100px 15px;
  }
  .nalog_services-title {
    font-size: 40px;
  }
  .nalog_services-list {
    columns: 1;
  }
  .nalog_services-list > * {
    width: 100%;
  }
}
</style>
