<template>
  <div class="container header-container">
    <div class="menu-btn" @click="open">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        height="32px"
        id="Layer_1"
        style="enable-background: new 0 0 32 32"
        viewBox="0 0 32 32"
        width="32px"
        xml:space="preserve"
      >
        <path
          d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2 s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2 S29.104,22,28,22z"
        />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    open() {
      this.$store.commit("openMenu", true);
    },
  },
};
</script>
<style>
.header-container {
  pointer-events: none;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  z-index: 110;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 3000px;
  background-color: #ffffff;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  box-shadow: 0px 0px 5px 5px rgba(189, 189, 189, 0.3);
  cursor: pointer;
  pointer-events: all;
  z-index: 110;
}

@media screen and (max-width: 1200px) {
  .menu-btn {
    width: 50px;
    height: 50px;
  }

  .menu-btn svg {
    width: 30px;
    height: 30px;
  }
}
</style>
