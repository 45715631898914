import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/HomeView.vue";
import NotFound from "@/views/404View.vue";
import Page from "@/views/PageView.vue";
import Components from "@/views/ComponentsView.vue";
import BankruptcyView from "@/views/BankruptcyView.vue";
import Portfolio from "@/views/PortfolioView.vue";
import Nalog from "@/views/NalogView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/ys/components",
    name: "components",
    component: Components,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: Page,
  },
  {
    path: "/library",
    name: "library",
    component: BankruptcyView,
  },
  {
    path: "/case",
    name: "case",
    component: Portfolio,
  },
  {
    path: "/nalog",
    name: "nalog",
    component: Nalog,
  },
  {
    path: "/404",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

export default router;
