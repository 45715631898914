<template>
  <section id="bankruptcy-section">
    <div class="container bankruptcy-container">
      <h2>{{ bankruptcy?.title }}</h2>
      <div class="bankruptcy-list">
        <div
          class="bankruptcy-item"
          @click="open(item)"
          v-for="item in bankruptcy?.list"
        >
          <span>{{ item?.number }}</span>
          <p v-html="item?.text"></p>
        </div>
      </div>
    </div>
    <BankruptPopup :popup="this.popup_info" />
  </section>
</template>
<script>
import BankruptPopup from "./BankruptPopup.vue";

export default {
  components: {
    BankruptPopup,
  },
  props: {
    bankruptcy: {
      title: "",
      list: [],
    },
  },
  data() {
    return {
      popup_info: {},
    };
  },
  methods: {
    open(item) {
      this.$store.commit("openBankrupt", true);
      this.popup_info = item;
    },
  },
};
</script>
<style>
#bankruptcy-section {
  padding-top: 65px;
  background-color: #ededed;
  padding-bottom: 65px;
}

.bankruptcy-container {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.bankruptcy-container h2 {
  transform-origin: center center;
  font-size: 46px;
  font-weight: 600;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  text-align: center;
  color: var(--black);
}

.bankruptcy-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
}

.bankruptcy-item {
  display: block;
  background-color: var(--white);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 5px 30px 27px;
  gap: 8px;
  text-decoration: none;
  cursor: pointer;
}

.bankruptcy-item span {
  text-decoration: none;
  padding-top: 20px;
  color: var(--black);
  font-size: 16px;
  line-height: 1.35;
  font-weight: 600;
}

.bankruptcy-item p {
  text-decoration: none;
  color: var(--black);
  font-weight: 300;
  font-size: 14px;
  line-height: 1.55;
}

@media screen and (max-width: 1200px) {
  .bankruptcy-list {
    gap: 20px;
  }

  .bankruptcy-container h2 {
    font-size: 30px;
  }

  .bankruptcy-item span {
    font-size: 14px;
  }

  .bankruptcy-item p {
    color: rgba(0, 0, 0, 0.9);
    font-size: 12px;
  }

  #bankruptcy-section {
    padding-top: 43px;
  }

  .bankruptcy-container {
    gap: 40px;
  }
}

@media screen and (max-width: 960px) {
  .bankruptcy-list {
    grid-template-columns: 1fr;
  }

  .bankruptcy-container h2 {
    text-align: left;
    font-size: 20px;
  }

  .bankruptcy-item span {
    font-size: 12px;
  }

  .bankruptcy-item p {
    color: rgba(0, 0, 0, 0.9);
    font-size: 12px;
  }

  .bankruptcy-container {
    gap: 20px;
  }
}
</style>
