<template>
  <section>
    <div class="container reasons-container">
      <div class="reasons-title">
        <h2 v-html="reason?.title"></h2>
        <div class="portfolio-container">
          <p v-html="reason?.subtitle"></p>
          <a href="/case" class="reasons-portfolio-btn"> Портфолио </a>
        </div>
      </div>
      <div class="reasons-list">
        <div class="reasons-item" v-for="item in reason?.list">
          <img :src="item.icon" alt="" />
          <div class="reasons-item-text">
            <span v-html="item.title"></span>
            <p v-html="item?.text"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    reason: {
      title: "",
      subtitle: "",
      list: [],
    },
  },
};
</script>
<style>
.container.reasons-container {
  margin-top: 200px;
  margin-bottom: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  gap: 200px;
}

.reasons-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  min-width: max-content;
}

.reasons-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 50px;
  column-gap: 100px;
}

.reasons-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
}

.reasons-item-text {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.reasons-item-text span {
  font-size: 24px;
  font-weight: 700;
}

.reasons-item-text p {
  font-size: 20px;
  font-weight: 400;
}

.reasons-item img {
  width: 60px;
}

.reasons-title h2 {
  font-size: 50px;
}

.portfolio-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.portfolio-container p {
  color: #3d3d3d;
  font-size: 20px;
  font-weight: 700;
}

.reasons-portfolio-btn {
  text-decoration: none;
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  background: var(--grad-to);
  border-radius: 10px;
  width: max-content;
  align-items: center;
  padding: 20px 100px;
  transition: 0.5s;
}

.reasons-portfolio-btn:hover {
  background: var(--grad-from);
}

@media screen and (max-width: 1720px) {
  .container.reasons-container {
    gap: 100px;
  }

  .reasons-title h2 {
    font-size: 40px;
  }

  .reasons-list {
    row-gap: 40px;
    column-gap: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .container.reasons-container {
    flex-direction: column;
  }

  .reasons-list {
    grid-template-columns: 1fr;
  }

  .reasons-title {
    gap: 50px;
  }

  .container.reasons-container {
    gap: 50px;
  }

  .reasons-title h2 {
    font-size: 30px;
  }

  .portfolio-container p {
    font-size: 16px;
  }

  .reasons-portfolio-btn {
    font-size: 16px;
    padding: 15px 65px;
  }

  .reasons-item-text span {
    font-size: 18px;
  }

  .reasons-item-text p {
    font-size: 16px;
  }

  .reasons-item-text {
    gap: 10px;
  }

  .container.reasons-container {
    margin-top: 0px;
    margin-bottom: 150px;
  }
}
</style>
