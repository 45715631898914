<template>
  <section id="main_services">
    <div class="container services-container">
      <div v-for="(item, index) in services?.list">
        <div class="services-container-item" v-html="item.text"></div>
        <div
          class="question"
          v-if="services?.count == index"
          :class="services?.count == index ? 'add-margin' : ''"
        >
          <h3>Имеются вопросы?</h3>
          <div class="services-question-container">
            <button class="services-question-btn" @click="openPopup">
              Оставить заявку
            </button>
            <div class="services-question-text">
              Оставьте ваши контактные<br />
              данные, и мы вам перезвоним<br />
              в ближайшее время
            </div>
          </div>
        </div>
      </div>
      <div class="question mob">
        <h3>Имеются вопросы?</h3>
        <div class="services-question-container">
          <button class="services-question-btn" @click="openPopup">
            Оставить заявку
          </button>
          <div class="services-question-text">
            Оставьте ваши контактные<br />
            данные, и мы вам перезвоним<br />
            в ближайшее время
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    services: {
      count: 1,
      list: [],
    },
  },
  methods: {
    openPopup() {
      this.$store.commit("openPopup", true);
      const body = document.querySelector("body");
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      const menu = document.querySelector(".header-container");
      menu.style.paddingRight = `${scrollbarWidth / 2 + 15}px`;
      body.style.paddingRight = `${scrollbarWidth}px`;
      body.classList.add("hidden");
    },
  },
};
</script>
<style>
.services-container {
  columns: 2;
}

.services-container-item,
.question {
  margin-bottom: 20px;
  width: 100%;
  float: left;
  page-break-inside: avoid;
  break-inside: avoid;
}

.question.add-margin {
  margin-top: 150px;
}

.question.mob {
  display: none;
}

.services-container-item h3 {
  color: var(--black);
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 15px;
}

.services-container-item li {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.services-question-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.services-question-btn {
  color: var(--white);
  padding: 15px 70px;
  font-size: 18px;
  text-decoration: none;
  line-height: 1.55;
  font-weight: 900;
  border-radius: 10px;
  background-color: var(--blur-blue);
  transition: 0.5s;
  outline: none;
  border: none;
}

.services-question-btn:hover {
  background-color: var(--black);
}

.question h3 {
  color: var(--blur-blue);
  font-weight: 900;
  margin-bottom: 30px;
}
@media screen and (max-height: 840px) and (min-width: 1200px) {
  .question.add-margin {
    margin-top: 50px;
  }
}
@media screen and (max-width: 1200px) {
  .services-container {
    columns: 1;
  }
  .question.add-margin {
    display: none;
  }
  .question.mob {
    display: block;
  }
  .services-container-item h3 {
    font-size: 24px;
  }

  .services-container-item li {
    font-size: 16px;
  }

  .services-question-btn {
    white-space: nowrap;
    font-size: 14px;
    padding: 10px 30px;
  }

  .services-question-text {
    font-size: 14px;
  }

  .question h3 {
    font-size: 24px;
  }
}
</style>
