<template>
  <main>
    <PortfolioMain
      :main="page_data.main"
      :sliders="page_data.slider_portfolio"
    />
    <Slider
      v-for="(item, index) in page_data.slider_portfolio"
      :reverse="index % 2 != 0"
      :slider_index="'swiper-' + index"
      :first="index == 0"
      :portfolio_slider="item"
    />
    <a href="/library" class="portfolio-bankruptcy-btn">Все банкротные дела</a>
    <SectionOrder />
  </main>
</template>
<script>
import PortfolioMain from "@/components/portfolio/PortfolioMain.vue";
import Slider from "@/components/portfolio/PortfolioSlider.vue";
import SectionOrder from "@/components/UI/SectionOrder.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    PortfolioMain,
    Slider,
    SectionOrder,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("page/case");
  },
};
</script>
<style>
.portfolio-bankruptcy-btn {
  display: block;
  margin: 0px auto;
  width: max-content;
  color: var(--blur-blue);
  text-decoration: none;
  font-size: 20px;
  border-radius: 15px;
  padding: 15px 30px;
  border: 1px solid var(--blur-blue);
  transition: 0.5s;
}

.portfolio-bankruptcy-btn:hover {
  color: var(--white);
  background: var(--blur-blue);
}

@media screen and (max-width: 1200px) {
  .portfolio-bankruptcy-btn {
    font-size: 16px;
  }
}
</style>
