<template>
  <div :class="'popup ' + ($store.state[type] ? 'active' : '')">
    <div class="popup-bg" @click="popupClose()"></div>
    <div class="popup-container">
      <div class="popup-header">
        <slot name="header"> </slot>
        <svg
          @click="popupClose()"
          role="presentation"
          class="t-popup__close-icon"
          width="23px"
          height="23px"
          viewBox="0 0 23 23"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g stroke="none" stroke-width="1" fill="#fff" fill-rule="evenodd">
            <rect
              transform="translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) "
              x="10.3137085"
              y="-3.6862915"
              width="2"
              height="30"
            ></rect>
            <rect
              transform="translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) "
              x="10.3137085"
              y="-3.6862915"
              width="2"
              height="30"
            ></rect>
          </g>
        </svg>
      </div>
      <div class="popup-content">
        <slot> </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: "",
  },
  methods: {
    popupClose() {
      this.$store.commit("successPopup", false);
      const body = document.querySelector("body");
      const menu = document.querySelector(".header-container");
      menu.style.paddingRight = "15px";
      body.style.paddingRight = "0px";
      body.classList.remove("hidden");
      this.$emit("popupClose");
    },
    emit: ["popupClose"],
  },
};
</script>
<style>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100lvh;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup.active {
  opacity: 1;
  pointer-events: all;
}

.popup-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 50;
}

.popup-container {
  width: 560px;
  background-color: var(--white);
  height: auto;
  z-index: 100;
  padding: 40px 45px;
}

.popup-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 24px;
}

.popup-header h3 {
  font-size: 20px;
  line-height: 1.2;
  color: var(--black);
  font-weight: 700;
  font-family: JuraBold;
}

.popup-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.popup-header svg {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .popup {
    align-items: flex-end;
  }
  .popup .popup-bg {
    background-color: var(--black);
  }
  .popup-header svg {
    width: 15px;
    height: 15px;
    right: 20px;
  }
  .popup-container {
    height: calc(100vh - 50px);
    padding: 20px;
  }
  .popup-header h3 {
    text-align: center;
  }
}
</style>
