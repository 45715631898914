<template>
  <section id="proud">
    <div class="container proud-container">
      <img
        class="main-bg-rectangel"
        src="@/assets/img/bg-main-rectangle.svg"
        alt=""
      />
      <div class="proud-container-text">
        <h2 class="desktop">{{ proud?.title }}</h2>
        <div class="proud-list">
          <div class="proud-item" v-for="item in proud?.list">
            <img :src="item.img" alt="" />
            <p v-html="item.text"></p>
          </div>
        </div>
        <a class="proud-btn" href="/case"
          >Более 100 кейсов в одном месте</a
        >
      </div>
      <div class="swiper-container">
        <div class="swiper" ref="proudSlider">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in proud?.slider"
              @click="newIndex(index)"
            >
              <div class="swiper-slide-container">
                <img :src="item.img" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="proud-slider-btns">
          <div class="proud-slider-button-prev">
            <svg
              class="t-null__slds-arrow t-null__slds-arrow_1"
              width="94"
              height="94"
              viewBox="0 0 94 94"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="display: block"
            >
              <path
                d="M39 68L60 47L39 26"
                stroke="black"
                vector-effect="non-scaling-stroke"
                style="stroke-width: 1px; stroke: rgb(0, 0, 0)"
              ></path>
            </svg>
          </div>
          <div class="proud-slider-button-next">
            <svg
              class="t-null__slds-arrow t-null__slds-arrow_1"
              width="94"
              height="94"
              viewBox="0 0 94 94"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="display: block"
            >
              <path
                d="M39 68L60 47L39 26"
                stroke="black"
                vector-effect="non-scaling-stroke"
                style="stroke-width: 1px; stroke: rgb(0, 0, 0)"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <h2 class="proud-title mobile" v-html="proud?.title_m"></h2>
    </div>
    <ProudPopup :activeIndex="activeIndex" :slides="this.popup_info" />
  </section>
</template>
<script>
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import ProudPopup from "@/components/home/ProudPopup.vue";
// import "swiper/css";

export default {
  data() {
    return {
      activeIndex: 0,
      popup_info: [],
    };
  },
  props: {
    proud: {
      title: "", //пк
      list: [],
      slider: [],
      title_m: "", //моб
    },
  },
  components: {
    ProudPopup,
  },
  methods: {
    newIndex(index) {
      this.$store.commit("openProud", true);
      this.activeIndex = index;
      this.popup_info = this.proud?.slider;
    },
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.$refs.proudSlider) {
        const swiper = new Swiper(this.$refs.proudSlider, {
          autoplay: { delay: 3000 },
          loop: true,
          navigation: {
            nextEl: ".proud-slider-button-next",
            prevEl: ".proud-slider-button-prev",
          },
          effect: "fade",
          fadeEffect: {
            crossFade: true,
          },
        });
        clearInterval(interval);
      }
    }, 50);
  },
};
</script>
<style>
#proud {
  margin: 150px 0px;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

.main-bg-rectangel {
  position: absolute;
  width: 1000px;
  height: 950px;
  top: -200px;
  left: 0px;
  transform: translateX(-50%) translateY(-50%) rotate(17deg);
  opacity: 0.55;
  z-index: -1;
}

.container.proud-container {
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 100px;
}

.proud-container-text {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.proud-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.proud-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
}

.proud-item p {
  font-size: 20px;
  font-weight: 900;
  max-width: 325px;
}

.proud-item img {
  width: 200px;
  height: auto;
}

.proud-container-text h2 {
  font-size: 50px;
}

.proud-btn {
  display: block;
  color: var(--white);
  width: max-content;
  text-decoration: none;
  font-weight: 700;
  font-size: 20px;
  background: var(--black);
  border-radius: 10px;
  padding: 15px 30px;
  transition: 0.5s;
}

.proud-btn:hover {
  background-color: var(--grad-to);
}

.swiper-container {
  width: 565px;
  height: auto;
  position: relative;
  display: block;
}

.proud-slider-button-prev svg,
.proud-slider-button-next svg {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.proud-slider-button-prev svg {
  transform: rotate(180deg);
}

.proud-slider-button-prev,
.proud-slider-button-next {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--white);
}

.proud-slider-btns {
  border-radius: 10px;
  background-color: var(--grad-to);
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  width: max-content;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%) translateY(calc(50% - 55px));
  z-index: 10;
}

.proud-container .swiper-slide {
  width: 525px;
  padding: 20px;
}

.proud-container .swiper-slide .swiper-slide-container {
  border-radius: 15px;
  background-color: var(--white);
  box-shadow: 0px 0px 20px 1px rgba(163, 163, 163, 0.5);
  padding: 50px;
  cursor: zoom-in;
}

.proud-container .swiper-slide img {
  width: 425px;
  height: auto;
}

@media screen and (max-width: 1200px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .container.proud-container {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .proud-title {
    font-size: 30px;
    font-weight: 900;
    margin-bottom: -60px;
  }

  .swiper-container {
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  .proud-container .swiper-slide img {
    width: 100%;
  }

  .proud-container .swiper-slide .swiper-slide-container {
    padding: 10px;
    border-radius: 10px;
  }

  .proud-item p {
    font-size: 16px;
  }

  .proud-item img {
    width: 100px;
  }

  .proud-item {
    gap: 15px;
  }

  .proud-btn {
    font-size: 16px;
    margin: 0px auto;
  }

  .container.proud-container {
    gap: 75px;
  }

  .proud-slider-button-prev svg,
  .proud-slider-button-next svg,
  .proud-slider-button-prev,
  .proud-slider-button-next {
    width: 35px;
    height: 35px;
  }

  .proud-slider-btns {
    gap: 15px;
  }

  .main-bg-rectangel {
    display: none;
  }
}
</style>
