<template>
  <section id="main">
    <div class="container main__wrapper">
      <div class="main-content">
        <div class="main-header">
          <img class="main-header-logo" :src="main?.logo" alt="" />
          <img class="main-header-mobile-logo" :src="main?.logo_m" alt="" />
          <div class="main-header-info">
            <span>{{ main?.firma }}</span>
            <img class="main-header-helmet" :src="main?.icon" alt="" />
            <span>{{ main?.years }}</span>
          </div>
        </div>
        <h1 class="main-body" v-html="main?.title"></h1>
        <div class="main-footer">
          <div v-html="main?.list_one"></div>
          <div v-html="main?.list_two"></div>
          <img :src="require('@/assets/img/right-arrow.svg')" alt="" />
          <button @click="openPopup">Оставить заявку</button>
          <ul class="main-cities">
            <li v-for="item in main?.cities">{{ item.city }}</li>
          </ul>
        </div>
        <div class="main-img-mobile">
          <img :src="main?.img" alt="" />
        </div>
      </div>
      <div class="main-img">
        <img :src="main?.img" alt="" />
      </div>
      <div class="main-decor">
        <img :src="require('@/assets/img/svg/Rectangle 16.png')" alt="" />
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    main: {
      logo: "",
      logo_m: "",
      firma: "",
      icon: "",
      years: "",
      title: "",
      list_one: "",
      list_two: "",
      cities: [],
      img: "",
    },
  },
  methods: {
    openPopup() {
      this.$store.commit("openPopup", true);
      const body = document.querySelector("body");
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      const menu = document.querySelector(".header-container");
      menu.style.paddingRight = `${scrollbarWidth / 2 + 15}px`;
      body.style.paddingRight = `${scrollbarWidth}px`;
      body.classList.add("hidden");
    },
  },
};
</script>
<style>
.container.main__wrapper {
  padding: 80px 20px 100px;
  height: 100%;
}
.main-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.main-header {
  display: flex;
  align-items: center;
  gap: 40px;
}
.main-header-info {
  display: flex;
  align-items: center;
  gap: 20px;
}
.main-header-info span {
  color: #bfbfbf;
  font-family: JuraRegular;
  font-size: 18px;
  line-height: 17px;
}
.main-header-logo {
  width: 245px;
  height: 37px;
}
.main-header-helmet {
  width: 30px;
  height: 30px;
}
.main-body {
  /* margin-top: 160px; */
  max-width: 700px;
  font-size: 80px;
  font-family: Juraregular;
  font-weight: 500;
  display: inline-flex;
  flex-direction: column;
  line-height: normal;
}
.main-body span {
  align-self: flex-end;
  margin-top: -20px;
}
.main-footer {
  /* margin-top: 180px; */
  display: grid;
  grid-template-columns: 150px 2fr 1fr;
  max-width: 55%;
  gap: 50px 30px;
}

.main-footer > :nth-child(1) {
  grid-area: 1 / 2 / 2 / 3;
}

.main-footer > :nth-child(2) {
  grid-area: 1 / 3 / 2 / 4;
}

.main-footer > :nth-child(3) {
  grid-area: 2 / 1 / 3 / 2;
}

.main-footer > :nth-child(4) {
  grid-area: 2 / 2 / 3 / 3;
}

.main-footer > :nth-child(5) {
  grid-area: 2 / 3 / 3 / 4;
}

.main-footer ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.main-footer ul,
.main-footer ul li {
  list-style: none;
  color: var(--white);
  font-family: JuraBold;
}

.main-footer img {
  width: 40px;
  place-self: center start;
}
.main-footer button {
  background-color: var(--white);
  color: var(--black);
  padding: 20px 70px;
  border: none;
  outline: none;
  font-size: 20px;
  font-family: JuraSemiBold;
  font-weight: 600;
  border-radius: 15px;
  height: max-content;
  width: calc(100% - 50px);
  place-self: center;
  transition: 0.5s;
}
.main-footer button:hover {
  background-color: var(--black);
  color: var(--white);
}

.main-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 640px;
  height: 100%;
}
.main-img img {
  width: 100%;
  height: 100%;
  display: block;
}
.main-decor {
  position: absolute;
  top: 66%;
  left: 1%;
  width: 900px;
  height: 900px;
  rotate: 238deg;
  z-index: -1;
  opacity: 1;
}
.main-decor img {
  width: 100%;
  height: 100%;
  display: block;
}
.main-footer ul.main-cities {
  padding: 0;
}
.main-footer ul.main-cities li::marker {
  content: "";
}
.main-img-mobile,
.main-header-mobile-logo {
  display: none;
}
@media screen and (max-height: 840px) and (min-width: 1200px) {
  .main-decor {
    width: 600px;
    height: 600px;
  }
  .main-footer button {
    padding: 20px;
  }
}
@media screen and (max-width: 1400px) {
  .main-img {
    width: 400px;
  }
}

@media screen and (max-width: 1200px) {
  .main-img {
    display: none;
  }
  .container.main__wrapper {
    padding: 30px 50px;
  }
  .main-header-logo {
    width: 160px;
    height: auto;
  }
  .main-body {
    margin-top: 80px;
    font-size: 60px;
    max-width: 530px;
  }
  .main-footer {
    width: 100%;
    max-width: none;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 50px;
    gap: 30px;
  }
  .main-footer > :nth-child(1),
  .main-footer > :nth-child(2),
  .main-footer > :nth-child(3),
  .main-footer > :nth-child(4),
  .main-footer > :nth-child(5) {
    grid-area: auto;
  }

  .main-footer ul.main-cities,
  .main-footer img {
    display: none;
  }
  .main-footer button {
    width: 100%;
    grid-area: 2 / 1 / 3 / 3;
  }
  .main-decor {
    z-index: 0;
    top: -10%;
    left: -92%;
    width: 2000px;
    height: 2000px;
    rotate: 160deg !important;
    transform: none !important;
  }
}

@media screen and (max-width: 500px) {
  .main-decor {
    display: none;
  }
  .main-img {
    display: none;
  }
  .main-header-info {
    display: none;
  }
  .main-header-logo {
    display: none;
  }
  .main-header-mobile-logo {
    display: block;
    width: 140px;
    height: auto;
  }
  .container.main__wrapper {
    padding: 20px 15px;
  }
  .main-body {
    margin-top: 50px;
    font-size: 40px;
  }
  .main-footer {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .main-footer button {
    margin-top: 30px;
    grid-area: auto;
  }
  .main-img-mobile {
    display: block;
    margin-top: 50px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
  }
  .main-img-mobile img {
    width: 100%;
    height: 100%;
    max-height: 350px;
    object-fit: cover;
    object-position: top;
    border-radius: 30px;
  }
}
</style>
