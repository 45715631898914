<template>
  <div class="nalog_service-row">
    <div class="nalog_service-card color">
      <div class="nalog_service-card-title" v-html="card?.title"></div>
      <img :src="require('@/assets/img/logo-white-notext.png')" alt="" />
    </div>
    <div class="nalog_service-card">
      <div class="nalog_service-card-title mobile" v-html="card?.title"></div>
      <div class="nalog_service-card-content">
        <ul>
          <li v-for="item in card?.content">{{ item.text }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    card: {
      title: "",
      content: [],
    },
  },
};
</script>
<style>
.nalog_service-row {
  display: flex;
  gap: 30px;
}
.nalog_service-card {
  width: 100%;
  border-radius: 30px;
  padding: 50px;
  background-color: var(--white);
}
.nalog_service-card.color {
  background: linear-gradient(
    to right bottom,
    var(--grad-from) 0%,
    var(--grad-to) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--white);
  font-size: 40px;
  font-family: JuraBold;
  line-height: 1.2;
  font-weight: 700;
  gap: 40px;
}
.nalog_service-card.color img {
  width: 50px;
}
.nalog_service-card-title.mobile {
  display: none;
}
.nalog_service-card-content {
  color: var(--dark);
  font-size: 20px;
  font-family: JuraSemiBold;
  font-weight: 600;
}
.nalog_service-card-content ul {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

@media screen and (max-width: 1200px) {
  .nalog_service-card.color {
    display: none;
  }
  .nalog_service-card {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 15px;
  }
  .nalog_service-card-title.mobile br {
    display: none;
  }
  .nalog_service-card-title.mobile {
    display: block;
    color: var(--blur-blue);
    font-size: 16px;
    font-family: JuraSemiBold;
  }
  .nalog_service-card-content {
    font-size: 12px;
  }
  .nalog_service-card-content ul {
    padding-left: 10px;
  }
}
</style>
