<template>
  <section id="portfolio-main">
    <div class="container portfolio-section">
      <img
        class="bg-rect"
        :src="require('@/assets/img/svg/Rectangle 16.png')"
        alt=""
      />
      <div class="bg-main-man">
        <div class="bg-main-man-container">
          <img :src="require('@/assets/img/man-bg-1.svg')" alt="" />
          <img :src="require('@/assets/img/man-bg-1.svg')" alt="" />
          <img :src="main?.img" alt="" />
        </div>
      </div>
      <div class="main-top">
        <div class="main-top-left">
          <img :src="main?.logo" alt="" />
          <p>{{ main?.firm }}</p>
        </div>
        <div class="main-top-right">
          <a href="/">вернуться на главную</a>
          <div class="main-top-btn" @click="openPopup">
            Заказать обратный звонок
          </div>
        </div>
      </div>
      <h1 v-html="main?.title"></h1>
      <div class="directions">
        <span class="directions-title"
          ><img src="@/assets/img/arrow-white.svg" alt="" />Выберите
          направление</span
        >
        <div class="directions-grid">
          <a
            :href="'#swiper-' + index"
            class="directions-item"
            v-for="(item, index) in sliders"
            v-html="item?.title"
          ></a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SmoothScroll from "smooth-scroll";

export default {
  props: {
    main: {
      img: "",
      logo: "",
      firm: "",
      title: "Более 1200<br />успешных дел",
    },
    sliders: [],
  },
  methods: {
    openPopup() {
      this.$store.commit("openPopup", true);
      const body = document.querySelector("body");
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      const menu = document.querySelector(".header-container");
      menu.style.paddingRight = `${scrollbarWidth / 2 + 15}px`;
      body.style.paddingRight = `${scrollbarWidth}px`;
      body.classList.add("hidden");
    },
  },
  mounted() {
    const scroll = new SmoothScroll('a.directions-item[href*="#swiper"]');
  },
};
</script>
<style>
#portfolio-main {
  height: 100lvh;
  background-image: url("@/assets/img/gradient-bg.png"),
    linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
  background-size: cover;
  position: relative;
  overflow-x: clip;
}

.container.portfolio-section {
  padding: 100px 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1600px;
}

.main-top,
#portfolio-main h1,
.directions {
  z-index: 100;
}

.portfolio-section h1 {
  font-size: 72px;
}

.main-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.main-top-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
}

.main-top-left img {
  width: 250px;
}

.main-top-left p {
  color: var(--gray);
  font-size: 20px;
  font-weight: 400;
}

.main-top-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
}

.main-top-right a {
  font-size: 20px;
  font-weight: 600;
  color: var(--gray);
  text-decoration: none;
}

.main-top-btn {
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  border: 1px solid var(--white);
  padding: 15px 25px;
  cursor: pointer;
}

.directions {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.directions-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  color: var(--white);
  font-size: 20px;
  font-weight: 300;
}

.directions-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 800px;
}

.directions-item {
  display: block;
  width: max-content;
  background-color: var(--white);
  color: var(--blur-blue);
  white-space: nowrap;
  font-size: 18px;
  border-radius: 10px;
  padding: 15px 45px;
  text-decoration: none;
}

.directions-item br {
  display: none;
}

.bg-rect {
  position: absolute;
  left: -110px;
  bottom: -385px;
  transform: rotate(238deg);
  width: 849px;
  height: 970px;
}

.bg-main-man {
  position: absolute;
  top: 100px;
  right: -320px;
}

.bg-main-man-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.bg-main-man-container {
  position: relative;
  width: 1000px;
  height: 768px;
}

.bg-main-man-container img:nth-child(1) {
  opacity: 0.1;
  top: 9px;
  left: 0px;
  max-height: 950px;
  z-index: 10;
}

.bg-main-man-container img:nth-child(2) {
  opacity: 0.2;
  top: 55px;
  max-height: 950px;
  left: 0px;
  z-index: 20;
}

.bg-main-man-container img:nth-child(3) {
  z-index: 30;
  top: -15px;
  left: 0px;
}

@media screen and (max-width: 1440px) {
  .container.portfolio-section {
    padding: 75px 15px;
  }

  .bg-main-man {
    right: -146px;
  }

  .bg-main-man-container {
    width: 755px;
  }

  .bg-rect {
    left: -50px;
    bottom: -325px;
    width: 549px;
    height: 670px;
  }

  .directions-item {
    font-size: 15px;
    padding: 11px 25px;
  }
}

@media screen and (max-width: 1200px) {
  #portfolio-main {
    height: auto;
  }

  .directions-grid {
    width: 100%;
  }

  .container.portfolio-section {
    padding: 50px 15px;
    gap: 30px;
  }

  .main-top {
    flex-direction: column;
    align-items: flex-start;
  }

  .main-top-left {
    flex-direction: row;
    gap: 10px;
  }

  .main-top-right {
    margin-top: 40px;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 15px;
  }

  .main-top-left img {
    width: 135px;
  }

  .main-top-left p {
    display: none;
    font-size: 17px;
  }

  .main-top-btn {
    font-size: 16px;
    padding: 10px 25px;
  }

  .main-top-right a {
    font-size: 16px;
  }

  .portfolio-section h1 {
    font-size: 48px;
  }

  .bg-main-man {
    right: -80px;
    top: 10px;
  }

  .bg-main-man-container {
    width: 450px;
  }

  .bg-main-man-container img:nth-child(3) {
    top: 0px;
  }

  .bg-main-man-container img:nth-child(2) {
    top: 33px;
  }

  .bg-rect {
    left: -90px;
    bottom: 0px;
    width: 305px;
    height: 320px;
  }

  .directions-item {
    font-size: 15px;
    padding: 10px 15px;
  }

  .directions {
    gap: 15px;
  }

  .container.portfolio-section {
    gap: 50px;
  }
}

@media screen and (max-width: 670px) {
  .bg-main-man {
    display: none;
  }
}
</style>
