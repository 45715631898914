<template>
  <section id="our-juris">
    <svg
      class="bg-rectangel"
      xmlns="http://www.w3.org/2000/svg"
      width="1012"
      height="1116"
      viewBox="0 0 1012 1116"
      fill="none"
    >
      <path
        d="M1012 181.289C1012 81.166 930.769 0 830.566 0H181.434C81.2308 0 0 81.1661 0 181.289V695.713C0 771.677 47.3983 839.586 118.74 865.836L767.872 1104.67C886.317 1148.25 1012 1060.67 1012 934.551V181.289Z"
        fill="url(#paint0_linear_701_5)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_701_5"
          x1="991.025"
          y1="36.5811"
          x2="-38.8598"
          y2="1010.68"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E9E9E9" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
    <div class="container our-juris-container">
      <div class="our-juris-img">
        <img :src="juris?.img" alt="" />
        <div class="our-juris-img-text" v-html="juris?.info"></div>
      </div>
      <div class="our-juris-text-container">
        <span v-html="juris?.name"></span>
        <p>{{ juris?.text }}</p>
        <div class="our-juris-text-btns">
          <a href="/case">портфолио</a>
          <a href="/">на главный сайт</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    juris: {
      img: "",
      info: "",
      name: "",
      text: "",
    },
  },
};
</script>
<style>
#our-juris {
  padding-top: 150px;
  background: url("@/assets/img/gradient-bg.png");
  background-size: cover;
  position: relative;
  margin-top: -150px;
  overflow: hidden;
}

.bg-rectangel {
  position: absolute;
  width: 496px;
  opacity: 0.2;
  height: auto;
  right: 7%;
  top: -303px;
}

.container.our-juris-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
}

.our-juris-img {
  margin-bottom: -2px;
  position: relative;
}

.our-juris-img-text {
  color: var(--white);
  position: absolute;
  height: 235px;
  width: 214px;
  top: 95px;
  right: 0;
  background: url("@/assets/img/rectangle.svg");
  background-size: cover;
  font-size: 24px;
  font-weight: 600;
  padding-left: 30px;
  padding-top: 50px;
  display: flex;
}

.container.our-juris-container img {
  transform: scale(-1, 1);
  width: 500px;
}

.our-juris-text-container {
  display: flex;
  flex-direction: column;
  max-width: 465px;
  gap: 25px;
  margin: auto 0px;
}

.our-juris-text-container span {
  font-size: 50px;
  font-weight: 500;
  color: var(--white);
}

.our-juris-text-container p {
  font-size: 18px;
  color: var(--white);
}

.our-juris-text-container a {
  color: var(--white);
  text-decoration: none;
  font-size: 18px;
  line-height: 1.55;
  font-weight: 600;
  border-radius: 10px;
  background: transparent;
  border: 1px solid var(--white);
  width: 302px;
  text-align: center;
  padding: 15px;
  transition: 0.5s;
}

.our-juris-text-container a:hover {
  color: var(--blur-blue);
  background: var(--white);
}

.our-juris-text-btns {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

@media screen and (max-width: 1200px) {
  .container.our-juris-container {
    gap: 50px;
    display: flex;
    flex-direction: column-reverse;
  }
  .container.our-juris-container img {
    width: 100%;
  }
  .our-juris-img-text {
    display: none;
  }
  .our-juris-text-container span {
    font-size: 40px;
  }
  .our-juris-text-container p {
    font-size: 18px;
  }
  .our-juris-text-container {
    width: 100%;
    max-width: 100%;
  }
  .our-juris-text-btns {
    flex-direction: row;
    gap: 10px;
  }
  #our-juris {
    padding-top: 200px;
  }
  .bg-rectangel {
    left: 0;
  }

  .our-juris-text-container a {
    font-size: 14px;
    padding: 10px;
    width: 152px;
  }
}
</style>
