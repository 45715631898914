<template>
  <div class="nalog">
    <NalogMain :main="page_data.data?.main" />
    <NalogServices :services="page_data.data?.services"/>
    <Reasons :reason="page_data.data?.reason"/>
    <NalogForm />
    <OurJuris :juris="page_data.data?.juris" />
  </div>
</template>
<script>
import NalogMain from "@/components/nalog/NalogMain.vue";
import Reasons from "@/components/nalog/Reasons.vue";
import OurJuris from "@/components/nalog/OurJuris.vue";
import NalogServices from "@/components/nalog/NalogServices.vue";
import NalogForm from "@/components/UI/NalogForm.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Reasons,
    OurJuris,
    NalogMain,
    NalogServices,
    NalogForm,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("page/nalog");
  },
};
</script>
<style>
.nalog {
  background-color: #e6e6e6;
}
</style>
