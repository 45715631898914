<template>
  <main>
    <Bankrupty :bankruptcy="page_data.data?.bankruptcy" />
  </main>
</template>
<script>
import Bankrupty from "@/components/bankrupt/Bankruptcy.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "BankruptyView",
  components: {
    Bankrupty,
  },
  methods: {
    ...mapActions({
      getPageData: "page/getPageData",
    }),
  },
  computed: {
    ...mapState({
      page_data: (state) => state.page.page_data,
    }),
  },
  async created() {
    await this.getPageData("page/library");
  },
};
</script>
<style scoped>
section {
  padding: 100px 0px;
}
</style>
