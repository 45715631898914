<template>
  <div
    class="proud-popup"
    :class="$store.state.openProud == true ? 'open' : ''"
  >
    <div class="container">
      <div class="close-btn" @click="close">
        <svg
          width="20"
          height="20"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.41421 -0.000151038L0 1.41406L21.2132 22.6273L22.6274 21.2131L1.41421 -0.000151038Z"
            fill="#000000"
          ></path>
          <path
            d="M22.6291 1.41421L21.2148 0L0.00164068 21.2132L1.41585 22.6274L22.6291 1.41421Z"
            fill="#000000"
          ></path>
        </svg>
      </div>
      <div class="proud-slider-next">
        <svg
          class="t-null__slds-arrow t-null__slds-arrow_1"
          width="94"
          height="94"
          viewBox="0 0 94 94"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="display: block"
        >
          <path
            d="M39 68L60 47L39 26"
            stroke="black"
            vector-effect="non-scaling-stroke"
            style="stroke-width: 1px; stroke: rgb(0, 0, 0)"
          ></path>
        </svg>
      </div>
      <div class="proud-slider-prev">
        <svg
          class="t-null__slds-arrow t-null__slds-arrow_1"
          width="94"
          height="94"
          viewBox="0 0 94 94"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="display: block"
        >
          <path
            d="M39 68L60 47L39 26"
            stroke="black"
            vector-effect="non-scaling-stroke"
            style="stroke-width: 1px; stroke: rgb(0, 0, 0)"
          ></path>
        </svg>
      </div>
      <div class="swiper" ref="proudSliderPopup">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in slides">
            <div class="swiper-slide-container">
              <img :src="item.img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/swiper-bundle.css";

export default {
  props: {
    activeIndex: 1,
    slides: [],
  },
  data() {
    return {
      swiper: null,
    };
  },
  methods: {
    close() {
      this.$store.commit("openProud", false);
    },
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.$refs.proudSliderPopup) {
        this.swiper = new Swiper(this.$refs.proudSliderPopup, {
          loop: true,
          navigation: {
            nextEl: ".proud-slider-next",
            prevEl: ".proud-slider-prev",
          },
        });
        clearInterval(interval);
      }
    }, 50);
  },
  watch: {
    activeIndex(new_value) {
      this.swiper.activeIndex = new_value;
      this.swiper.update();
    },
  },
};
</script>
<style>
.proud-popup {
  width: 100vw;
  height: 100lvh;
  background: var(--white);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 160;
  transition: 0.8s;
  opacity: 0;
  pointer-events: none;
}

.proud-popup.open {
  opacity: 1;
  pointer-events: all;
}

.proud-popup .container {
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.proud-popup.open .swiper-slide-container {
  transform: translateY(0%);
}

.proud-popup .container .swiper {
  height: 100%;
}

.proud-popup .container .swiper img {
  width: auto;
  height: 100%;
}

.proud-popup .swiper-slide-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
  transition: 0.8s;
  transform: translateY(100%);
}

.proud-slider-next,
.proud-slider-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 110;
  cursor: pointer;
  width: 200px;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.proud-slider-next svg,
.proud-slider-prev svg {
  width: 50px;
  height: 50px;
}

.proud-slider-prev {
  transform: translateY(-50%) rotate(180deg);
  left: 50px;
}

.proud-slider-next {
  right: 50px;
}

.close-btn {
  position: absolute;
  cursor: pointer;
  top: 25px;
  right: 25px;
  z-index: 110;
}

@media screen and (max-width: 1200px) {
  .proud-slider-prev,
  .proud-slider-next {
    width: 70px;
  }

  .proud-slider-prev {
    left: -5px;
  }

  .proud-slider-next {
    right: -5px;
  }
}

@media screen and (max-width: 780px) {
  .proud-popup .container .swiper img {
    width: 100%;
    height: auto;
    max-width: 600px;
  }
}
</style>
