<template>
  <Menu />
  <MenuNav />
  <Preloader />
  <router-view @click="close()" />
  <Footer />
  <PopupOrder />
  <SuccessPopup />
</template>

<script>
import Preloader from "@/components/base/Preloader.vue";
import PopupOrder from "./components/UI/PopupOrder.vue";
import SuccessPopup from "./components/UI/SuccessPopup.vue";
import MenuNav from "./components/UI/MenuNav.vue";
import Menu from "./components/UI/Menu.vue";
import Footer from "./components/UI/Footer.vue";
export default {
  name: "App",
  components: {
    Preloader,
    PopupOrder,
    SuccessPopup,
    Menu,
    MenuNav,
    Footer,
  },
  methods: {
    close() {
      this.$store.commit("openMenu", false);
    },
  },
};
</script>

<style>
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --black: #000;
  --gray: #e0e0e0;
  --dark: #353535;
  --grad-from: #1d485e;
  --grad-to: #45a1af;
  --blur-blue: #6a9fab;
  --error: #ff0000;
}

h1 {
  color: var(--white);
  font-size: 55px;
  font-family: JuraLight;
  line-height: 1.1;
}
h2 {
  font-size: 80px;
  font-family: JuraSemiBold;
  line-height: normal;
  color: var(--black);
  line-height: 1.1;
}
h3 {
  color: var(--black);
  font-size: 30px;
  font-family: JuraBold;
  line-height: 1.2;
}
#app {
  font-family: JuraRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--white);
  overflow-x: hidden;
  min-height: 100vh;
}

body.hidden {
  margin: 0px;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
}

.container {
  max-width: 1600px;
  padding: 0 15px;
  margin: 0 auto;
}
ul {
  padding-left: 14px;
}
ul li::marker {
  content: "> ";
}

@media screen and (max-width: 500px) {
  body.hidden {
    padding: 0;
  }
}
</style>
