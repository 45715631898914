<template>
  <section :id="slider_index" class="acts">
    <div class="container acts-container">
      <div :class="reverse == true ? 'reverse' : ''" class="acts-container-top">
        <h2
          :class="first == true ? 'first' : ''"
          v-html="portfolio_slider?.title"
        ></h2>
        <div class="act-swiper-btns">
          <div class="swiper-button-prev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="45"
              viewBox="0 0 33 24"
              fill="none"
            >
              <path
                d="M32.0607 13.0607C32.6464 12.4749 32.6464 11.5251 32.0607 10.9393L22.5147 1.3934C21.9289 0.807612 20.9792 0.807612 20.3934 1.3934C19.8076 1.97918 19.8076 2.92893 20.3934 3.51472L28.8787 12L20.3934 20.4853C19.8076 21.0711 19.8076 22.0208 20.3934 22.6066C20.9792 23.1924 21.9289 23.1924 22.5147 22.6066L32.0607 13.0607ZM-2.74758e-09 13.5L31 13.5L31 10.5L2.74758e-09 10.5L-2.74758e-09 13.5Z"
                fill="#3D8E9E"
              />
            </svg>
          </div>
          <div class="swiper-button-next">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="45"
              viewBox="0 0 33 24"
              fill="none"
            >
              <path
                d="M32.0607 13.0607C32.6464 12.4749 32.6464 11.5251 32.0607 10.9393L22.5147 1.3934C21.9289 0.807612 20.9792 0.807612 20.3934 1.3934C19.8076 1.97918 19.8076 2.92893 20.3934 3.51472L28.8787 12L20.3934 20.4853C19.8076 21.0711 19.8076 22.0208 20.3934 22.6066C20.9792 23.1924 21.9289 23.1924 22.5147 22.6066L32.0607 13.0607ZM-2.74758e-09 13.5L31 13.5L31 10.5L2.74758e-09 10.5L-2.74758e-09 13.5Z"
                fill="#3D8E9E"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="swiper act-swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="item in portfolio_slider?.slider_cards"
          >
            <p v-html="item.text"></p>
            <a :href="item.link" class="act-swiper-btn" target="_blank"
              >Судебный акт</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Swiper from "swiper";
import "swiper/css";

export default {
  props: {
    portfolio_slider: {
      title: "",
      slider_cards: [],
    },
    reverse: false,
    first: false,
    slider_index: "",
  },
  data() {
    return {
      swiper: null,
    };
  },
  mounted() {
    const interval = setInterval(() => {
      const swiper_wrapper = document.querySelector(
        "#" + this.slider_index + " .swiper"
      );
      if (swiper_wrapper) {
        this.swiper = new Swiper(swiper_wrapper, {
          mousewheel: false,
          spaceBetween: 20,
          loop: true,
          navigation: {
            nextEl: "#" + this.slider_index + " .swiper-button-next",
            prevEl: "#" + this.slider_index + " .swiper-button-prev",
          },
          breakpoints: {
            600: {
              slidesPerView: "auto",
            },
            360: {
              slidesPerView: 1,
            },
          },
        });
        clearInterval(interval);
      }
    }, 50);
  },
};
</script>
<style>
.container.acts-container {
  position: relative;
  max-width: 1600px;
  padding: 0px 15px;
  z-index: 100;
}

.acts {
  overflow-x: hidden;
  padding: 150px 0px;
}

.acts h2 {
  color: var(--black);
  font-size: 48px;
  font-weight: 600;
}

.acts h2.first {
  color: var(--white);
}

.act-swiper {
  margin-top: 100px;
  overflow-x: visible !important;
}
.act-swiper .swiper-wrapper {
  align-items: stretch !important;
}
.act-swiper .swiper-slide {
  background-color: var(--white);
  border-radius: 25px;
  height: auto;
  gap: 30px;
  width: 375px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--gray);
}

.act-swiper .swiper-slide p {
  font-size: 18px;
  font-weight: 600;
  color: var(--black);
}

.act-swiper-btn {
  color: var(--grad-to);
  border: 1px solid var(--grad-to);
  text-decoration: none;
  width: max-content;
  border-radius: 15px;
  padding: 10px 30px;
  transition: 0.5s;
  font-size: 16px;
  font-family: JuraSemiBold;
}
.act-swiper-btn:hover {
  color: var(--white);
  background-color: var(--grad-to);
}

.act-swiper-btns {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.act-swiper-btns .swiper-button-prev,
.act-swiper-btns .swiper-button-next {
  width: max-content;
  width: max-content;
  cursor: pointer;
}

.act-swiper-btns .swiper-button-prev svg {
  transform: rotate(180deg);
}

.acts .swiper-button-prev:after,
.acts .swiper-rtl .swiper-button-next:after {
  display: none;
}

.acts-container-top {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.acts-container-top.reverse {
  flex-direction: row-reverse;
  text-align: right;
}

.acts .swiper-button-prev:after,
.acts .swiper-button-next:after {
  display: none;
}

.acts .swiper-button-prev,
.acts .swiper-button-next {
  position: relative;
}

@media screen and (max-width: 1440px) {
  .acts {
    padding: 100px 0px;
  }
}

@media screen and (max-width: 1200px) {
  .acts h2.first {
    color: var(--black);
  }
}

@media screen and (max-width: 690px) {
  .acts h2 {
    font-size: 20px;
  }

  .act-swiper-btns {
    gap: 25px;
  }

  .act-swiper-btns svg {
    width: 34px;
    height: 24px;
  }

  .act-swiper {
    margin-top: 25px;
  }

  .acts {
    padding: 50px 0px;
  }
}

@media screen and (max-width: 600px) {
  .act-swiper .swiper-slide {
    width: 100%;
    height: auto;
    gap: 40px;
  }
}
</style>
