import axios from "axios";

export const appModule = {
  state: () => {
    return {
      // header: {},
      footer: {},
    };
  },
  getters: {},
  mutations: {
    // setHeader: (state, header) => {
    //   state.header = header;
    // },
    setFooter: (state, footer) => {
      state.footer = footer;
    },
  },
  actions: {
    // getHeader: async ({ commit }) => {
    //   try {
    //     const response = await axios.get(
    //       "https://your-startup.space/v1/methods/header"
    //     );
    //     if (response.status == 200) {
    //       commit("setHeader", response.data);
    //     }
    //   } catch (error) {
    //     console.log(error.message);
    //   }
    // },
    getFooter: async ({ commit }) => {
      try {
        const response = await axios.get(
          "http://dev.shubizle11.beget.tech/v1/methods/footer"
        );
        if (response.status == 200) {
          commit("setFooter", response.data);
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  namespaced: true,
};
