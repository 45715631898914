<template>
  <div class="consulting-card">
    <div class="consulting-card-header">
      <img :src="icon" alt="" />
      <h3 v-html="title"></h3>
    </div>
    <div class="consulting-card-info" v-html="content"></div>
    <a :href="link" class="consulting-card-btn" v-if="link">Подробнее</a>
    <a class="consulting-card-btn" v-else @click="openPopup">Подробнее</a>
  </div>
</template>
<script>
export default {
  props: {
    title: "",
    icon: "",
    content: "",
    link: "",
  },
  methods: {
    openPopup() {
      this.$store.commit("openPopup", true);
      const body = document.querySelector("body");
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      const menu = document.querySelector(".header-container");
      menu.style.paddingRight = `${scrollbarWidth / 2 + 15}px`;
      body.style.paddingRight = `${scrollbarWidth}px`;
      body.classList.add("hidden");
    },
  },
};
</script>
<style>
.consulting-card {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.consulting-card-header {
  display: flex;
  align-items: center;
  gap: 5px;
}
.consulting-card-header img {
  width: 80px;
  height: 80px;
}
.consulting-card-header h3 {
  color: var(--white);
}
.consulting-card-info {
  color: var(--white);
  font-size: 16px;
}
.consulting-card-info ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 30px;
}
.consulting-card-btn {
  margin: 10px auto 0;
  background-color: var(--blur-blue);
  color: var(--white);
  border: none;
  outline: none;
  border-radius: 15px;
  width: calc(100% - 80px);
  padding: 20px;
  font-family: JuraSemiBold;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

@media screen and (max-height: 840px) and (min-width: 1200px) {
  .consulting-card-header img {
    height: 60px;
    width: 60px;
  }

  .consulting-card-btn {
    padding: 10px;
    font-size: 16px;
    width: calc(100% - 120px);
    margin: 7px auto 0;
  }

  .consulting-card-header h3 {
    font-size: 26px;
  }

  .consulting__wrapper .consulting-cards {
    gap: 20px 50px;
  }

  .consulting-card-info ul li {
    font-size: 14px;
  }

  .consulting-card {
    gap: 5px;
  }
}
</style>
