<template>
  <div
    id="menu"
    class="menu-container"
    :class="$store.state.menuOpen == true ? 'open' : ''"
  >
    <div class="close" @click="close">Закрыть</div>
    <img :src="$store.state.site_info?.footer_logo" alt="" />
    <div class="nav-list">
      <a @click="close" class="nav-item" href="/">Начало</a>
      <a @click="close" class="nav-item" href="/#main_services">Услуги</a>
      <a @click="close" class="nav-item" href="/case">Портфолио</a>
      <a @click="close" class="nav-item" href="/#section_order">Контакты</a>
    </div>
    <div class="adress-list">
      <div
        class="adress-item"
        v-for="item in $store.state.site_info?.cities"
        v-html="item.text"
      ></div>
    </div>
    <div class="menu-form-btn" @click="openPopup">Обратный звонок</div>
  </div>
</template>
<script>
import SmoothScroll from "smooth-scroll";
export default {
  methods: {
    close() {
      this.$store.commit("openMenu", false);
    },
    openPopup() {
      this.$store.commit("openMenu", false);
      this.$store.commit("openPopup", true);
      const body = document.querySelector("body");
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      const menu = document.querySelector(".header-container");
      menu.style.paddingRight = `${scrollbarWidth / 2 + 15}px`;
      body.style.paddingRight = `${scrollbarWidth}px`;
      body.classList.add("hidden");
    },
  },
  mounted() {
    const scroll = new SmoothScroll('a.nav-item[href*="/#"]');
  },
};
</script>
<style>
.menu-container {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 300px;
  height: 100lvh;
  overflow-y: auto;
  z-index: 110;
  background: #1c1c1c;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  transition: 0.5s;
  transform: translateX(100%);
}

.menu-container.open {
  transform: translateX(0%);
}

.menu-container img {
  width: 100%;
}

.nav-list,
.adress-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nav-item {
  color: var(--white);
  text-decoration: none;
  font-size: 18px;
  line-height: 1.55;
  font-weight: 600;
  transition: 0.5s;
}

.adress-item {
  color: var(--white);
  font-size: 14px;
  opacity: 0.65;
}

.close {
  color: var(--white);
  cursor: pointer;
  transition: 0.5s;
}

.menu-form-btn {
  color: var(--white);
  font-size: 18px;
  cursor: pointer;
  transition: 0.5s;
}

.close:hover,
.nav-item:hover,
.menu-form-btn:hover {
  color: var(--blur-blue);
}

@media screen and (max-height: 740px) and (min-width: 1200px) {
  .menu-container {
    gap: 15px;
  }

  .nav-item {
    font-size: 14px;
  }

  .adress-item {
    font-size: 12px;
  }

  .menu-container img {
    width: 70%;
  }

  .close {
    font-size: 14px;
  }

  .nav-list,
  .adress-list {
    gap: 6px;
  }

  .menu-form-btn {
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .menu-container {
    width: 100%;
  }
}
</style>
