<template>
  <footer>
    <div class="container footer-container">
      <div class="footer-top">
        <a class="logo-footer desktop" href="#">
          <img :src="$store.state.site_info?.footer_logo" />
        </a>
        <p class="legal-data" v-html="$store.state.site_info?.data"></p>
        <a class="logo-footer mobile" href="#">
          <img :src="$store.state.site_info?.footer_logo_m" />
        </a>
        <div class="tg-channel" v-if="$store.state.site_info?.tg_link">
          <a class="tg-channel-btn" :href="$store.state.site_info?.tg_link"
            >Подписаться</a
          >
          <a class="tg-channel-text" :href="$store.state.site_info?.tg_link"
            >Следите за нами в Telegram!</a
          >
        </div>
      </div>
      <div class="footer-bottom">
        <div class="footer-bottom-adress">
          <div
            class="footer-bottom-adress-item"
            v-for="item in $store.state.site_info?.cities"
          >
            <p v-html="item.text"></p>
          </div>
        </div>
        <div class="footer-bottom-contacts">
          <a :href="'mailto:' + $store.state.site_info?.email">{{
            $store.state.site_info?.email
          }}</a>
          <a :href="'tel:' + $store.state.site_info?.phone">{{
            $store.state.site_info?.phone
          }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import siteInfo from "@/assets/js/api/site-info.js";
export default {
  async mounted() {
    const site_info = await siteInfo.get();
    this.$store.commit("setSiteInfo", site_info);
  },
};
</script>
<style>
footer {
  width: 100%;
  background-color: #1f1f1f;
  padding: 100px 0px;
}

.footer-top {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 50px;
}

.container.footer-container {
  max-width: 1600px;
}

.legal-data {
  font-size: 20px;
  height: max-content;
  margin: auto 0px;
  color: var(--white);
}

.logo-footer {
  display: flex;
  align-items: center;
}

.logo-footer img {
  width: 100%;
  height: auto;
}

.tg-channel {
  height: 100%;
  width: 100%;
  background-color: var(--white);
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  gap: 15px;
}

.tg-channel a {
  text-decoration: none;
}

.tg-channel-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid var(--black);
  padding: 15px 100px;
  color: #1f1f1f;
  font-size: 20px;
  line-height: 1.25;
  font-weight: 600;
}

.tg-channel-text {
  font-size: 24px;
  color: #1f1f1f;
  line-height: 1.25;
  font-weight: 600;
  margin: auto 0px;
}

.footer-top {
  padding-bottom: 50px;
  border-bottom: 3px solid var(--white);
}

.footer-bottom {
  padding-top: 50px;
  display: grid;
  gap: 50px;
  grid-template-columns: 3fr 1fr;
}

.footer-bottom-adress {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 110px;
  row-gap: 50px;
}

.footer-bottom-contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
}

.footer-bottom-contacts a {
  font-weight: 500;
  color: var(--white);
  text-decoration: none;
  font-size: 24px;
}

.footer-bottom-adress-item {
  color: var(--white);
  font-weight: 400;
  font-size: 18px;
}

.logo-footer.mobile {
  display: none;
}

@media screen and (max-width: 1515px) {
  .footer-top {
    gap: 30px;
  }

  .legal-data {
    padding-left: 40px;
    font-size: 14px;
  }

  .tg-channel {
    padding: 15px;
  }

  .tg-channel-btn {
    padding: 15px 80px;
  }

  .footer-bottom-adress {
    gap: 80px;
    row-gap: 40px;
  }

  .footer-bottom-adress-item {
    font-size: 14px;
  }

  .footer-bottom-contacts a {
    font-size: 20px;
  }
}

@media screen and (max-width: 1200px) {
  footer {
    padding: 50px 0px;
  }

  .logo-footer.desktop {
    display: none;
  }

  .logo-footer.mobile {
    display: block;
    margin-top: 30px;
  }

  .footer-top {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    padding-bottom: 25px;
  }

  .tg-channel-btn {
    padding: 10px 40px;
    font-size: 14px;
  }

  .tg-channel-text {
    font-size: 14px;
  }

  .footer-bottom-adress {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .footer-bottom {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  .logo-footer img {
    max-width: 200px;
  }

  .footer-bottom-contacts {
    align-items: flex-start;
  }
}
</style>
