<template>
  <section id="nalog-main">
    <div class="container nalog__wrapper">
      <img
        class="nalog-rect"
        :src="require('@/assets/img/svg/Rectangle 16.png')"
        alt=""
      />
      <img
        class="nalog-rect"
        :src="require('@/assets/img/svg/Rectangle 16.png')"
        alt=""
      />
      <div class="main-top">
        <div class="main-top-left">
          <img :src="main?.logo" alt="" />
          <p>{{ main?.firm }}</p>
        </div>
        <div class="main-top-right">
          <a href="/">на сайт компании</a>
          <button class="main-top-btn" @click="openPopup">
            Заказать обратный звонок
          </button>
        </div>
        <!-- <div class="main-top-right-mobile">
          <a href="">Узнать подробнее</a>
        </div> -->
      </div>
      <h1 v-html="main?.title"></h1>
      <div class="nalog-main-footer">
        <ul class="nalog-main-footer-list">
          <li v-for="item in main?.list">{{ item.text }}</li>
        </ul>
        <div class="nalog-main-footer-btns">
          <img :src="require('@/assets/img/arrow-white.svg')" alt="" />
          <button @click="openPopup">Повысить эффективность</button>
          <a href="#nalog_serices">Узнать подробнее</a>
        </div>
      </div>
      <div class="nalog-main-employee">
        <img :src="main?.person_img" alt="" />
        <div class="nalog-main-employee-info">
          <span v-html="main?.person_name"></span>
          <div v-html="main?.person_post"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SmoothScroll from "smooth-scroll";

export default {
  props: {
    main: {
      logo: "",
      firm: "",
      title:
        "Команда для повышения <br />налоговой и финансовой <br />эффективности Вашего <br />бизнеса",
      list: [],
      person_img: "",
      person_name: "Анна Сергеевна <br />Деревянченко",
      person_post: "Эксперт по налогам <br />и налогооблажению",
    },
  },
  methods: {
    openPopup() {
      this.$store.commit("openPopup", true);
      const body = document.querySelector("body");
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      const menu = document.querySelector(".header-container");
      menu.style.paddingRight = `${scrollbarWidth / 2 + 15}px`;
      body.style.paddingRight = `${scrollbarWidth}px`;
      body.classList.add("hidden");
    },
  },
  mounted() {
    const scroll = new SmoothScroll('.nalog-main-footer-btns a[href*="#"]');
  },
};
</script>
<style scoped>
#nalog-main {
  height: 100lvh;
  background-image: url("@/assets/img/gradient-bg.png"),
    linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
  background-size: cover;
  position: relative;
  overflow: clip;
}

.container.nalog__wrapper {
  padding: 100px 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1600px;
}

.main-top,
#nalog-main h1,
.nalog-main-footer {
  z-index: 100;
}

.nalog__wrapper h1 {
  font-weight: 300;
  font-size: 64px;
}

.main-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.main-top-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
}

.main-top-left img {
  width: 250px;
}

.main-top-left p {
  color: var(--gray);
  font-size: 20px;
  font-weight: 400;
}

.main-top-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
}

.main-top-right a {
  font-size: 20px;
  font-weight: 600;
  color: var(--gray);
  text-decoration: none;
}

.main-top-btn {
  background-color: transparent;
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  border: 1px solid var(--white);
  padding: 15px 25px;
  cursor: pointer;
}

.nalog-rect {
  position: absolute;
  left: 40%;
  bottom: -600px;
  transform: rotate(238deg);
  width: 849px;
  height: 970px;
  pointer-events: none;
  z-index: 3;
}
.nalog-rect:nth-child(2) {
  top: 30px;
  left: 30%;
  z-index: 0;
  opacity: 0.3;
  width: 1500px;
  height: 1500px;
}

.nalog-main-employee {
  position: absolute;
  top: 220px;
  right: 200px;
}

.nalog-main-employee img {
  width: 600px;
}

.nalog-main-employee-info {
  position: absolute;
  top: 100px;
  right: -50px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.nalog-main-employee-info span {
  color: var(--white);
  font-size: 20px;
  font-family: JuraSemiBold;
}
.nalog-main-employee-info div {
  color: var(--white);
  font-size: 20px;
}
.nalog-main-footer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 50%;
}
.nalog-main-footer-list li {
  font-family: JuraRegular;
  font-size: 20px;
  color: var(--white);
}
.nalog-main-footer-btns {
  display: grid;
  grid-template-columns: 42px 1fr 1fr;
  gap: 30px;
  place-items: center;
}
.nalog-main-footer-btns button {
  padding: 20px 50px;
  outline: none;
  border: none;
  color: var(--black);
  background-color: var(--white);
  border-radius: 20px;
  transition: 0.5s;
  font-size: 18px;
  font-family: JuraSemiBold;
  width: 100%;
}
.nalog-main-footer-btns button:hover {
  color: var(--blur-blue);
  background-color: var(--black);
}
.nalog-main-footer-btns a {
  color: var(--white);
  background-color: transparent;
  border-radius: 20px;
  text-align: center;
  font-size: 18px;
  font-family: JuraSemiBold;
  transition: 0.5s;
  padding: 20px 50px;
  border: 1px solid var(--white);
  text-decoration: none;
  width: 100%;
}
.nalog-main-footer-btns a:hover {
  background-color: var(--white);
  color: var(--blur-blue);
}

/* .main-top-right-mobile {
  display: none;
} */
@media screen and (max-width: 1440px) {
  .container.nalog__wrapper {
    padding: 75px 15px;
  }

  .nalog-main-employee {
    right: -146px;
  }

  .nalog-main-employee-container {
    width: 755px;
  }

  .directions-item {
    font-size: 15px;
    padding: 11px 25px;
  }
}
@media screen and (max-height: 790px) and (min-width: 1200px) {
  .container.nalog__wrapper {
    padding: 50px 20px;
  }
  .nalog-main-footer {
    max-width: 60%;
  }
  .nalog-main-footer-btns button,
  .nalog-main-footer-btns a {
    padding: 20px;
  }
  .main-top-right {
    margin-right: 60px;
  }
  .nalog-main-employee {
    top: 100px;
    right: 150px;
  }
  .nalog-main-employee-info {
    right: -100px;
  }
  .nalog-main-employee img {
    width: 400px;
  }
  .nalog-rect {
    width: 700px;
    height: 700px;
    bottom: -450px;
  }
  .nalog-main-footer-list li {
    font-size: 18px;
    line-height: normal;
  }
  .nalog__wrapper h1 {
    font-size: 48px;
  }
}
@media screen and (max-width: 1200px) {
  .nalog-main-employee {
    display: none;
  }
  #nalog-main {
    height: auto;
  }

  .directions-grid {
    width: 100%;
  }

  .container.nalog__wrapper {
    padding: 30px 80px 100px 50px;
    gap: 30px;
  }
  .nalog-main-footer-btns button {
    order: 3;
  }
  .nalog-main-footer-btns a {
    order: 2;
  }
  .nalog-main-footer-btns button,
  .nalog-main-footer-btns a {
    padding: 15px 10px;
  }
  .nalog-main-footer {
    max-width: 90%;
    flex-direction: column-reverse;
  }
  .main-top-left {
    flex-direction: row;
    gap: 10px;
  }

  .main-top-right {
    gap: 15px;
    margin-right: 0;
  }

  .main-top-left img {
    width: 135px;
  }

  .main-top-left p {
    display: none;
  }

  .main-top-btn {
    font-size: 16px;
    padding: 10px 25px;
  }

  .main-top-right a {
    font-size: 16px;
  }

  .nalog-rect {
    left: 17%;
  }
  .nalog-rect:nth-child(2) {
    transform: rotate(230deg);
    top: -100px;
    left: 0%;
    width: 2000px;
    height: 1400px;
  }

  .container.nalog__wrapper {
    gap: 50px;
  }
}

@media screen and (max-width: 500px) {
  #nalog-main {
    height: 100lvh;
  }
  .main-top-right {
    display: none;
  }
  .container.nalog__wrapper {
    /* padding: 100px 15px; */
    padding: 20px 15px 100px;
    gap: 50px;
    justify-content: flex-start;
  }
  /* .main-top-right-mobile {
    display: block;
  }
  .main-top-right-mobile a {
    color: var(--white);
    background-color: transparent;
    border-radius: 10px;
    text-align: center;
    font-size: 12px;
    font-family: JuraSemiBold;
    transition: 0.5s;
    padding: 10px;
    border: 1px solid var(--white);
    text-decoration: none;
    width: 100%;
  } */
  .nalog__wrapper h1 br {
    display: none;
  }
  .nalog__wrapper h1 {
    font-size: 34px;
    line-height: 100%;
  }
  .nalog-main-footer {
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .nalog-main-footer-list li {
    font-size: 14px;
  }
  .nalog-main-footer-btns {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    width: max-content;
  }
  .nalog-main-footer-btns button {
    order: 1;
  }
  .nalog-main-footer-btns a {
    order: 2;
  }
  .nalog-main-footer-btns button,
  .nalog-main-footer-btns a {
    padding: 10px;
    font-size: 14px;
    border-radius: 10px;
  }
  .nalog-main-footer-btns img {
    width: 30px;
  }
  .nalog-rect {
    height: 500px;
    width: 500px;
    bottom: -300px;
    left: 22%;
    transform: rotate(230deg);
  }
  .nalog-rect:nth-child(2) {
    width: 1000px;
  }
  .nalog-main-employee {
    display: block;
    right: -50px;
    top: auto;
    bottom: -20px;
  }
  .nalog-main-employee img {
    width: 300px;
  }
  .nalog-main-employee-info {
    z-index: 10;
    top: 250px;
    right: 80px;
  }

  .nalog-main-employee-info span,
  .nalog-main-employee-info div {
    font-size: 10px;
  }
}
</style>
