export function randomInteger(min, max) {
  const rand = min - 0.5 + Math.random() * (max - min + 1);
  return Math.round(rand);
}

export function replaceToLatters(value, with_space = false) {
  if (value) {
    if (with_space) {
      value = value
        .replace(/[^a-zA-Zа-яА-Я]+$/g, "")
        .replace(/^\s+/g, "")
        .replace("  ", " ");
    } else {
      value = value.replace(/[^a-zA-Zа-яА-Я]/g, "");
    }

    return value;
  } else {
    return "";
  }
}
