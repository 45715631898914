import { createStore } from "vuex";
import { pageModule } from "./pageModule";
import { appModule } from "./appModule";

export default createStore({
  state: () => {
    return {
      orderPopupShow: false,
      vacancyPopupShow: false,
      vacancyDesc: "",
      vacancyName: "",
      is_scroll: false,
      menuOpen: false,
      mobileMenuClose: false,
      successFormOrder: false,
      answerOpen: false,
      budgetError: false,
      rateError: false,
      mapOpen: false,
      examplePopup: false,
      supportPopupShow: false,
      openPopup: false,
      openBankrupt: false,
      openProud: false,
      successPopup: false,
      site_info: {},
    };
  },
  getters: {},
  mutations: {
    openProud(state, bool) {
      state.openProud = bool;
    },
    openBankrupt(state, bool) {
      state.openBankrupt = bool;
    },
    examplePopup(state, bool) {
      state.examplePopup = bool;
    },
    setScroll(state, bool) {
      state.is_scroll = bool;
    },
    openMenu(state, bool) {
      state.menuOpen = bool;
    },
    closeMenu(state, bool) {
      state.mobileMenuClose = bool;
    },
    openPopup(state, bool) {
      state.openPopup = bool;
    },
    successPopup(state, bool) {
      state.successPopup = bool;
    },
    successOrder(state, bool) {
      state.successFormOrder = bool;
    },
    answerOpen(state, bool) {
      state.answerOpen = bool;
    },
    budgetError(state, bool) {
      state.budgetError = bool;
    },
    rateError(state, bool) {
      state.rateError = bool;
    },
    mapOpen(state, bool) {
      state.mapOpen = bool;
    },
    openVacancy(state, bool) {
      state.vacancyPopupShow = bool;
    },
    setVacancyDesc(state, string) {
      state.vacancyDesc = string;
    },
    setVacancyName(state, string) {
      state.vacancyName = string;
    },
    openSupport(state, bool) {
      state.supportPopupShow = bool;
    },
    setSiteInfo(state, object) {
      state.site_info = object;
    },
  },
  actions: {},
  modules: {
    page: pageModule,
    app: appModule,
  },
});
