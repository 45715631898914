<template>
  <div class="success-popup" :class="$store.state.successPopup ? 'active' : ''">
    <div class="popup-bg" @click="popupClose()"></div>
    <div class="success-popup-container">
      <div class="popup-header">
        <svg width="50" height="50" fill="#62C584" class="success-popup-icon">
          <path
            d="M25.1 49.28A24.64 24.64 0 0 1 .5 24.68 24.64 24.64 0 0 1 25.1.07a24.64 24.64 0 0 1 24.6 24.6 24.64 24.64 0 0 1-24.6 24.61zm0-47.45A22.87 22.87 0 0 0 2.26 24.68 22.87 22.87 0 0 0 25.1 47.52a22.87 22.87 0 0 0 22.84-22.84A22.87 22.87 0 0 0 25.1 1.83z"
          ></path>
          <path
            d="M22.84 30.53l-4.44-4.45a.88.88 0 1 1 1.24-1.24l3.2 3.2 8.89-8.9a.88.88 0 1 1 1.25 1.26L22.84 30.53z"
          ></path>
        </svg>
        <svg
          @click="popupClose()"
          class="success-popup-close"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 23 23"
        >
          <g fill-rule="evenodd">
            <path d="M0 1.41L1.4 0l21.22 21.21-1.41 1.42z"></path>
            <path d="M21.21 0l1.42 1.4L1.4 22.63 0 21.21z"></path>
          </g>
        </svg>
      </div>
      <div class="popup-content">Спасибо! Данные успешно отправлены.</div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    popupClose() {
      this.$store.commit("successPopup", false);
      const body = document.querySelector("body");
      const menu = document.querySelector(".header-container");
      menu.style.paddingRight = "15px";
      body.style.paddingRight = "0px";
      body.classList.remove("hidden");
    },
  },
};
</script>
<style>
.success-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100lvh;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
  transition: 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.success-popup.active {
  opacity: 1;
  pointer-events: all;
}
.success-popup-container {
  width: 360px;
  background-color: var(--white);
  height: auto;
  z-index: 100;
  padding: 40px 40px 50px;
  border-radius: 5px;
  text-align: center;
  position: relative;
  cursor: default;
}
.popup-header svg.success-popup-icon {
  position: static;
  cursor: default;
  width: 50px;
  height: 50px;
}

.popup-header svg.success-popup-close {
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
}

.success-popup-text {
  padding-top: 20px;
  color: var(--black);
}
@media screen and (max-width: 500px) {
  .success-popup-container {
    width: 320px;
  }
}
</style>
