<template>
  <section id="stages-section">
    <hr />
    <div class="container">
      <h2 v-html="stages?.title"></h2>
      <div class="stages-container">
        <div class="stages-item" v-for="item in stages?.list">
          <img class="stages-img" :src="item.img" alt="" />
          <span>{{ item.step }}</span>
          <p v-html="item.text"></p>
          <img src="@/assets/img/circle.svg" alt="" />
          <p class="time-limit">{{ item.limit }}</p>
        </div>
      </div>
      <a
        :href="stages?.presentation_link"
        v-if="stages?.presentation_link"
        class="stages-btn"
        target="_blank"
        >Полное описание этапов взыскания в банкротстве</a
      >
    </div>
  </section>
</template>
<script>
export default {
  props: {
    stages: {
      title: "Этапы взыскания<br />в процедуре банкротства",
      list: [],
      link: "",
    },
  },
};
</script>
<style>
#stages-section {
  position: relative;
  margin-bottom: 150px;
}

#stages-section h2 {
  font-size: 50px;
  text-align: center;
  margin-bottom: 90px;
}

#stages-section hr {
  position: absolute;
  top: 520px;
  width: 100%;
  height: 5px;
  background: var(--blur-blue);
  opacity: 0.55;
  z-index: 1;
}

#stages-section .container {
  border-radius: 55px;
  padding: 150px 0px;
  background: rgba(224, 224, 224, 0.35);
}

#stages-section .container h2,
#stages-section .stages-container {
  position: relative;
  z-index: 10;
}

.stages-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.stages-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 300px;
  text-align: center;
  margin: 0px auto;
}
.stages-item img:not(.stages-img) {
  width: 20px;
  height: 100%;
}

.stages-item p {
  font-weight: 700;
  font-size: 18px;
}

.stages-item span {
  font-weight: 900;
  font-size: 32px;
}

.stages-btn {
  display: block;
  text-align: center;
  width: max-content;
  margin: 50px auto 0;
  color: var(--blur-blue);
  border: 1px solid var(--blur-blue);
  border-radius: 15px;
  background-color: transparent;
  transition: 0.5s;
  text-decoration: none;
  padding: 20px 30px;
  font-family: JuraSemiBold;
  font-size: 18px;
  cursor: pointer;
}

.stages-btn:hover {
  background-color: var(--blur-blue);
  color: var(--white);
}

@media screen and (max-width: 1200px) {
  #stages-section hr {
    display: none;
  }

  #stages-section h2 {
    font-size: 30px;
  }

  .stages-container {
    grid-template-columns: 1fr;
    gap: 50px;
  }

  .stages-item span {
    font-size: 24px;
  }

  .stages-item p {
    font-size: 16px;
  }

  #stages-section .container {
    padding: 100px 0px;
    margin: 0px 15px;
  }

  #stages-section h2 {
    margin-bottom: 50px;
  }
  .stages-btn {
    font-size: 14px;
    width: calc(100% - 50px);
    padding: 10px;
  }
}
</style>
