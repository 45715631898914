<template>
  <div class="select" ref="select" @click="toggleSelect">
    <img class="select-flag" :src="this.default.flag" />
    <span class="select-triangle"></span>
    <span class="select-code">{{ this.default.code }}</span>
  </div>
  <div class="select-options" ref="body">
    <noindex>
      <div
        class="select-option-item"
        v-for="item in this.countries"
        @click="changeCountry(item)"
      >
        <div class="select-option-item-name">{{ item.name }}</div>
        <div class="select-option-item-right">
          <span class="select-option-item-code">{{ item.code }}</span>
          <img class="select-option-item-flag" :src="item.flag" />
        </div>
      </div>
    </noindex>
  </div>
</template>
<script>
import countries from "@/assets/js/countries.js";
export default {
  data() {
    return {
      countries: [],
      default: {
        name: "Russia",
        code: "+7",
        iso: "RU",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ru.svg",
        mask: "(###)###-##-##",
      },
    };
  },
  props: {},
  methods: {
    toggleSelect() {
      if (!this.$refs.body.classList.contains("active")) {
        this.$refs.body.classList.add("active");
      } else {
        this.$refs.body.classList.remove("active");
      }
    },
    changeCountry(item) {
      this.default = item;
      this.$refs.body.classList.remove("active");
      this.$emit("changeCountry", item);
    },
    emit: ["changeCountry"],
  },
  mounted() {
    this.countries = countries;
  },
};
</script>
<style>
.select {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 5px;
  font-size: 16px;
  cursor: pointer;
}
.section-order-block .select-flag {
  background-color: #c5c5c5;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 30px;
}
.section-order-block .select-triangle {
  position: relative;
  margin-left: 6px;
  border-style: solid;
  border-width: 8px 6px 0;
  border-color: #9a9a9a transparent transparent;
}
.section-order-block .select-code {
  position: relative;
  margin-left: 10px;
  font-size: 22px;
}

.select-options.active {
  display: block;
}
.select-options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  max-height: 300px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 7px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #397524 #a5adae;
}

.select-options::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
.select-options::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}

.select-options::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--grad-to);
}

.select-option-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  font-size: 18px;
  color: #000 !important;
  cursor: pointer;
}
.section-order-block .select-option-item span {
  position: static;
}
.select-option-item-name {
}
.select-option-item-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.select-option-item-code {
  position: static;
}
.select-option-item-flag {
  width: 30px;
  height: 10px;
  object-fit: contain;
}
</style>
