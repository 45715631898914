<template>
  <section id="consulting">
    <div class="container consulting__wrapper">
      <div class="consulting-cards">
        <ConsiltingCard
          v-for="item in consulting?.cards"
          :icon="item.icon"
          :title="item.title"
          :content="item.content"
          :link="item.link"
        />
      </div>
      <div class="consulting-list">
        Познакомьтесь с перечнем услуг
        <a href="#main_services">Все услуги</a>
        <button class="consulting-btn" @click="openPopup">
          Оставить заявку
        </button>
      </div>
      <div class="consulting-decor top">
        <img :src="require('@/assets/img/svg/consulting-top.svg')" alt="" />
      </div>
      <div class="consulting-decor bottom">
        <img :src="require('@/assets/img/svg/consulting-bottom.svg')" alt="" />
      </div>
    </div>
  </section>
</template>
<script>
import ConsiltingCard from "@/components/UI/ConsiltingCard.vue";
export default {
  components: {
    ConsiltingCard,
  },
  props: {
    consulting: {
      cards: [],
    },
  },
  methods: {
    openPopup() {
      this.$store.commit("openPopup", true);
      const body = document.querySelector("body");
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      const menu = document.querySelector(".header-container");
      menu.style.paddingRight = `${scrollbarWidth / 2 + 15}px`;
      body.style.paddingRight = `${scrollbarWidth}px`;
      body.classList.add("hidden");
    },
  },
};
</script>
<style>
.container.consulting__wrapper {
  position: relative;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 100%;
  justify-content: space-between;
}
.consulting-cards {
  padding: 0 100px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 30px 50px;
}
.consulting-list {
  align-self: center;
  width: calc(100% - 100px);
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 30px;
  padding: 20px 50px;
  font-family: JuraSemiBold;
  font-size: 22px;
}
.consulting-list a {
  width: calc((100% - 33%) / 2);
  border: 1px solid var(--black);
  background-color: var(--white);
  border-radius: 20px;
  padding: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--black);
  font-family: JuraBold;
  font-size: 20px;
  transition: 0.5s;
}
.consulting-list a:hover {
  background-color: #262626;
  color: var(--white);
}
.consulting-btn {
  width: calc((100% - 33%) / 3);
  border: 1px solid var(--black);
  border-radius: 20px;
  padding: 20px;
  background-color: var(--black);
  color: var(--white);
  font-family: JuraBold;
  font-size: 20px;
  transition: 0.5s;
}

.consulting-btn:hover {
  background-color: #262626;
}
.consulting-decor {
  position: absolute;
  z-index: -1;
}
.consulting-decor.top {
  bottom: 20%;
  left: -5%;
  rotate: 180deg;
}
.consulting-decor.bottom {
  top: 75%;
  left: -30%;
}

@media screen and (max-width: 1500px) {
  .container.consulting__wrapper {
    padding: 30px 0 0;
  }
  .consulting-card-header img {
    width: 50px;
    height: 50px;
  }
  .consulting-card-header h3 {
    font-size: 18px;
  }
  .consulting-card-info {
    font-size: 14px;
  }
  .consulting-card-btn {
    align-self: flex-end;
    font-size: 14px;
    padding: 15px;
    margin: 0 auto;
    width: calc(100% - 30px);
  }
  .consulting-list {
    align-self: center;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    border-radius: 20px;
    padding: 15px 20px;
    font-family: JuraSemiBold;
    font-size: 18px;
    max-width: 1000px;
  }
  .consulting-list a,
  .consulting-btn {
    border-radius: 15px;
    padding: 10px;
    font-size: 18px;
  }
}

@media screen and (max-width: 1200px) {
  .container.consulting__wrapper {
    padding: 50px 50px 300px;
  }
  .consulting-cards {
    padding: 0;
  }
  .consulting-decor.top {
    bottom: 57%;
    width: 2000px;
    height: 2000px;
    left: -85%;
    rotate: 180deg;
    opacity: 0.75;
  }
  .consulting-decor.bottom {
    display: none;
  }
  .consulting-card-btn {
    margin: 10px 0 0 30px;
    font-size: 16px;
    width: max-content;
    padding: 15px 60px;
  }
  .consulting-list {
    display: none;
  }
  .consulting-card:nth-child(4),
  .consulting-card:nth-child(5),
  .consulting-card:nth-child(6) {
    display: flex;
  }
}

@media screen and (max-width: 500px) {
  .container.consulting__wrapper {
    padding: 50px 15px 400px;
  }
  .consulting-decor.top {
    bottom: 85%;
    width: 100%;
    height: 100%;
    left: 180%;
    rotate: 180deg;
    opacity: 1;
  }
  .consulting-cards {
    gap: 20px;
  }
  .consulting-card-info ul {
    padding-left: 50px;
  }
  .consulting-card-btn {
    margin-left: 50px;
  }
}

@media screen and (max-height: 840px) and (min-width: 1200px) {
  .container.consulting__wrapper {
    padding-top: 20px;
  }
  .consulting-decor.top {
    left: 0;
  }
  .consulting-list a,
  .consulting-btn {
    padding: 15px;
    font-size: 16px;
  }

  .consulting-list {
    font-size: 18px;
    padding: 15px 50px;
    margin-top: 6px;
  }
}
</style>
