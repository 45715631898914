<template>
  <section id="section_order">
    <div class="container section-order__wrapper">
      <div class="section-order-content">
        <div class="section-order-title">
          <h2>Ответим <br />на все вопросы</h2>
        </div>
        <form action="" class="section-order-form">
          <div
            class="section-order-block"
            :class="error_phone == '' ? '' : 'inp-error'"
          >
            <CountrySelect ref="post" name="country" @changeCountry="setInfo" />
            <input
              ref="phone"
              type="tel"
              class="section-order-input"
              v-model="phone"
              name="phone"
            />
            <span :class="error_phone == '' ? '' : 'section-form-error'">{{
              error_phone
            }}</span>
          </div>
          <div
            class="section-order-block"
            :class="error_name == '' ? '' : 'inp-error'"
          >
            <input
              type="text"
              class="section-order-input"
              v-model="name"
              placeholder="Имя"
              name="name"
            />
            <span :class="error_name == '' ? '' : 'section-form-error'">{{
              error_name
            }}</span>
          </div>
          <select name="city" class="section-order-input" ref="city">
            <option value="Москва">Москва</option>
            <option value="Санкт-Петербург">Санкт-Петербург</option>
            <option value="Волгоград">Волгоград</option>
          </select>
          <div class="section-order-block">
            <button class="section-order-btn" @click.prevent.stop="createOrder">
              Оставить заявку
            </button>
            <p>
              Оставьте ваши контактные данные, и мы вам перезвоним в ближайшее
              время
            </p>
          </div>
        </form>
      </div>
    </div>
    <div class="section-person">
      <div class="section-decor">
        <img :src="require('@/assets/img/svg/section.svg')" alt="" />
      </div>
      <div class="section-decor">
        <img :src="require('@/assets/img/svg/section.svg')" alt="" />
      </div>
      <div class="section-decor">
        <img :src="require('@/assets/img/svg/section.svg')" alt="" />
      </div>
      <div class="section-person-man">
        <img :src="$store.state.site_info?.order?.person_img" alt="" />
      </div>
      <div class="section-person-info">
        <span class="section-person-info-name">{{
          $store.state.site_info?.order?.person_name
        }}</span>
        <span class="section-person-info-role">{{
          $store.state.site_info?.order?.person_post
        }}</span>
      </div>
    </div>
  </section>
</template>
<script>
import CountrySelect from "@/components/UI/CountrySelect.vue";
import inputmask from "@/assets/js/libs/inputmask.js";

import { replaceToLatters } from "@/assets/js/helper.js";
import orderApi from "@/assets/js/api/order.js";
export default {
  components: {
    CountrySelect,
  },
  props: {
    order: {
      person_img: "",
      person_name: "",
      person_post: "",
    },
  },
  data() {
    return {
      mask: "(999) 999-99-99",
      code: "+7",
      name: "",
      phone: "",
      error_name: "",
      error_phone: "",
      is_validated: false,
      error_order: "",
    };
  },
  methods: {
    setInfo(item) {
      let inp = this.$refs.phone;
      if (Array.isArray(item)) {
        this.placeholder = item.mask[0];
      } else {
        this.placeholder = item;
      }
      this.code = item.code;
      console.log(this.code);
      this.mask = item;
      inputmask(this.mask).mask(inp);
    },
    async createOrder() {
      this.error_order = "";
      let phone = this.code + " " + this.phone;
      if (this.validate()) {
        const result = await orderApi.create(
          this.name,
          phone,
          this.$refs.city.value
        );

        if (result.success) {
          this.name = "";
          this.phone = "";
          this.error_name = "";
          this.error_phone = "";
          this.is_validated = false;
          this.$store.commit("successPopup", true);
        } else {
          this.error_name = "";
          this.error_phone = "";
          this.is_validated = false;
          this.error_order = result.message;
        }
      }
    },
    validate() {
      this.error_name = "";
      this.error_phone = "";

      if (this.name == "") {
        this.error_name = "Обязательное поле";
      }

      const phone = this.phone.replaceAll("_", "");
      if (phone == "") {
        this.error_phone = "Обязательное поле";
      } else if (phone.length < this.mask.length) {
        this.error_phone = "Неверный формат";
      }

      this.is_validated = true;

      return this.error_name == "" && this.error_phone == "";
    },
  },
  watch: {
    name(new_value) {
      this.name = replaceToLatters(new_value);
      if (this.is_validated) {
        this.validate();
      }
    },
    phone() {
      if (this.is_validated) {
        this.validate();
      }
    },
  },
  mounted() {
    inputmask(this.mask).mask(document.querySelector("input[type='tel']"));
    document.addEventListener("click", () => {
      if (this.is_validated) {
        this.error_name = "";
        this.error_phone = "";
      }
    });
  },
};
</script>
<style>
#section_order {
  position: relative;
  height: 100vh;
  overflow-y: hidden;
  min-width: 100vw;
  flex: 0 0 auto;
}
.container.section-order__wrapper {
  padding-left: 45%;
  height: 100%;
}
.section-person {
  position: absolute;
  left: 0;
  top: calc(50% - 50px);
  translate: 0 -50%;
  height: 60%;
  width: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-top: 100px;
}
.person-decors {
  position: relative;
  display: flex;
}

.section-decor {
  position: absolute;
  height: 80%;
  width: 100%;
  bottom: -2px;
}

.section-decor:nth-child(1) {
  left: 10%;
}

.section-decor:nth-child(2) {
  left: -22%;
}

.section-decor:nth-child(3) {
  left: -50%;
}

.section-decor img {
  pointer-events: none;
  height: 100%;
  width: 100%;
}
.section-person-man {
  position: absolute;
  z-index: 1;
  max-width: 60%;
  left: 12%;
  scale: 1.1;
  bottom: 15%;
  height: 100%;
  transform: scaleX(-1);
}
.section-person-man img {
  width: 100%;
  pointer-events: none;
  filter: grayscale(1);
}
.section-person-info {
  position: absolute;
  z-index: 3;
  display: flex;
  flex-direction: column;
  left: 50px;
  bottom: 50px;
}
.section-person-info > * {
  color: var(--white);
  font-size: 18px;
  line-height: 1.2;
  font-weight: 400;
}

.section-order-content {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}
.section-order-title h2 {
  font-size: 50px;
}
.section-order-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.section-order-block:not(:last-of-type) {
  border: 1px solid var(--black);
  padding: 20px 30px;
}
.section-order-block {
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 15px;
  position: relative;
  transition: 0.5s;
}
.section-order-block.inp-error {
  border: 1px solid var(--error);
}
.section-order-block span {
  position: absolute;
}
.section-order-input {
  width: 100%;
  font-size: 22px;
  color: var(--black);
  outline: none;
  border: none;
}
.section-order-form select {
  border: 1px solid var(--black);
  border-radius: 15px;
  padding: 20px 30px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6.5 8L0.00480938 0.5L12.9952 0.5L6.5 8Z' fill='black'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 50%;
}

.section-order-input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.section-order-btn {
  width: 100%;
  background-color: var(--black);
  color: var(--white);
  padding: 20px 35px;
  border: none;
  outline: none;
  font-size: 20px;
  font-family: JuraSemiBold;
  font-weight: 600;
  border-radius: 15px;
  height: max-content;
  place-self: center;
  transition: 0.5s;
  text-decoration: none;
}

.section-order-form p {
  width: 100%;
  color: var(--black);
  font-size: 18px;
  line-height: 1.2;
}

.section-form-error {
  position: absolute;
  color: var(--error);
  background-color: var(--white);
  padding: 8px 10px;
  border-radius: 2px;
  z-index: 1;
  margin-top: 5px;
  left: 0;
  top: 100%;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.2);
  font-size: 22px;
  opacity: 1;
  transition: 0.5s;
}

.section-form-error::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -12px;
  left: 15%;
  border: 6px solid transparent;
  border-bottom-color: #fff;
}

.section-person-info-name,
.section-person-info-role {
  text-shadow: #000000 0 0px 3px;
}
@media screen and (max-width: 1500px) {
  .section-person {
    width: 40%;
  }
  .section-person-man {
    max-width: 70%;
    bottom: 0;
  }
  .section-decor {
    height: 70%;
  }
  .section-person-info {
    left: 20px;
  }
  .section-person-info-name,
  .section-person-info-role {
    font-size: 16px;
  }
}
@media screen and (max-width: 1200px) {
  .section-person-info {
    display: none;
  }
  .section-person {
    display: none;
    /* height: 500px; */
  }
  .section-decor {
    width: 450px;
    height: 100%;
  }
  .section-person-man {
    max-width: 350px;
    scale: 1;
    left: 0;
  }
  .section-decor:nth-child(3) {
    left: -70%;
  }
  .section-decor:nth-child(2) {
    left: -40%;
  }
  .section-decor:nth-child(1) {
    left: -10%;
  }
  .container.section-order__wrapper {
    padding: 0 20px;
  }
  .section-order-content {
    width: 100%;
  }
  .section-order-title h2 {
    font-size: 40px;
    line-height: 100%;
  }
  .section-order-block:not(:last-of-type),
  .section-order-form select {
    padding: 15px 30px;
  }
  .section-order-block:last-of-type {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
  .section-order-btn {
    padding: 20px 25px;
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  #section_order {
    margin-top: 50px;
    height: auto;
    overflow: clip;
  }
  .section-person-man {
    display: none;
  }
  .section-person {
    display: block;
    top: 0;
    width: 150%;
    height: 100%;
    z-index: -1;
    translate: 0;
    padding: 0;
  }
  .container.section-order__wrapper {
    padding: 100px 15px;
  }
  .section-decor {
    width: 150%;
  }
  .section-decor:nth-child(3) {
    left: -100%;
  }
  .section-decor:nth-child(2) {
    left: -50%;
  }
  .section-order-title h2 {
    font-size: 30px;
    color: var(--white);
  }
  .section-order-block:not(:last-of-type) {
    background-color: var(--white);
  }
  .section-order-form select {
    background-color: var(--white);
  }
  .section-order-block:last-of-type {
    gap: 15px;
    text-align: left;
  }
  .section-order-block:last-of-type p {
    font-size: 16px;
    color: var(--white);
  }
  .section-form-error {
    font-size: 16px;
  }
}
@media screen and (max-height: 790px) and (min-width: 1200px) {
  .section-person {
    height: 70%;
  }
}
</style>
