<template>
  <div class="nalog-form__wrapper">
    <div class="nalog-form-title">Мы вам перезвоним</div>
    <form action="" class="nalog-form">
      <div
        class="nalog-form-block"
        :class="error_phone == '' ? '' : 'inp-error'"
      >
        <CountrySelect ref="post" name="country" @changeCountry="setInfo" />
        <input
          ref="phone"
          type="tel"
          class="nalog-form-input"
          v-model="phone"
          name="phone"
        />
        <span :class="error_phone == '' ? '' : 'nalog-form-error'">{{
          error_phone
        }}</span>
      </div>
      <div
        class="nalog-form-block"
        :class="error_name == '' ? '' : 'inp-error'"
      >
        <input
          type="text"
          class="nalog-form-input"
          v-model="name"
          placeholder="Имя"
          name="name"
        />
        <span :class="error_name == '' ? '' : 'nalog-form-error'">{{
          error_name
        }}</span>
      </div>
      <select name="city" class="nalog-form-input" ref="city">
        <option value="Москва">Москва</option>
        <option value="Санкт-Петербург">Санкт-Петербург</option>
        <option value="Волгоград">Волгоград</option>
      </select>
      <div class="nalog-form-block">
        <button class="nalog-form-btn" @click.prevent.stop="createOrder">
          Оставить заявку
        </button>
      </div>
    </form>
    <p>
      Нажимая на кнопку, Вы даете согласие на обработку персональных данных в
      соответствии с Федеральным законом “О персональных данных” от 27.07.2006 №
      152-ФЗ и соглашаетесь с политикой конфиденциальности
    </p>
  </div>
</template>
<script>
import CountrySelect from "@/components/UI/CountrySelect.vue";
import inputmask from "@/assets/js/libs/inputmask.js";

import { replaceToLatters } from "@/assets/js/helper.js";
import orderApi from "@/assets/js/api/order.js";
export default {
  components: {
    CountrySelect,
  },
  data() {
    return {
      mask: "(999) 999-99-99",
      code: "+7",
      name: "",
      phone: "",
      error_name: "",
      error_phone: "",
      is_validated: false,
      error_order: "",
    };
  },
  methods: {
    setInfo(item) {
      let inp = this.$refs.phone;
      if (Array.isArray(item)) {
        this.placeholder = item.mask[0];
      } else {
        this.placeholder = item;
      }
      this.code = item.code;
      console.log(this.code);
      this.mask = item;
      inputmask(this.mask).mask(inp);
    },
    async createOrder() {
      this.error_order = "";
      let phone = this.code + " " + this.phone;
      if (this.validate()) {
        const result = await orderApi.create(
          this.name,
          phone,
          this.$refs.city.value
        );

        if (result.success) {
          this.name = "";
          this.phone = "";
          this.error_name = "";
          this.error_phone = "";
          this.is_validated = false;
          this.$store.commit("successPopup", true);
        } else {
          this.error_name = "";
          this.error_phone = "";
          this.is_validated = false;
          this.error_order = result.message;
        }
      }
    },
    validate() {
      this.error_name = "";
      this.error_phone = "";

      if (this.name == "") {
        this.error_name = "Обязательное поле";
      }

      const phone = this.phone.replaceAll("_", "");
      if (phone == "") {
        this.error_phone = "Обязательное поле";
      } else if (phone.length < this.mask.length) {
        this.error_phone = "Неверный формат";
      }

      this.is_validated = true;

      return this.error_name == "" && this.error_phone == "";
    },
  },
  watch: {
    name(new_value) {
      this.name = replaceToLatters(new_value);
      if (this.is_validated) {
        this.validate();
      }
    },
    phone() {
      if (this.is_validated) {
        this.validate();
      }
    },
  },
  mounted() {
    inputmask(this.mask).mask(document.querySelector("input[type='tel']"));
    document.addEventListener("click", () => {
      if (this.is_validated) {
        this.error_name = "";
        this.error_phone = "";
      }
    });
  },
};
</script>
<style>
.nalog-form__wrapper {
  position: relative;
  z-index: 50;
  background-color: var(--white);
  border-radius: 30px;
  padding: 50px 100px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.nalog-form-title {
  color: var(--dark);
  font-size: 60px;
  font-family: JuraSemiBold;
  line-height: 1;
  font-weight: 600;
}
.nalog-form {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.nalog-form-block:not(:last-of-type) {
  border: 1px solid var(--black);
  padding: 20px;
}
.nalog-form-block {
  display: flex;
  gap: 15px;
  align-items: center;
  border-radius: 15px;
  position: relative;
  transition: 0.5s;
}
.nalog-form-block .select-flag {
  background-color: #c5c5c5;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 18px;
}
.nalog-form-block .select-triangle {
  position: relative;
  margin-left: 6px;
  margin-top: 2px;
  border-style: solid;
  border-width: 5px 4px 0;
  border-color: #9a9a9a transparent transparent;
}
.nalog-form-block span.select-option-item-code,
.nalog-form-block span.select-code {
  position: static;
}
.nalog-form-block .select-code {
  font-size: 22px;
  margin-left: 7px;
}
.nalog-form-block.inp-error {
  border: 1px solid var(--error);
}
.nalog-form-block span {
  position: absolute;
}
.nalog-form-input {
  width: 100%;
  font-size: 22px;
  color: var(--black);
  outline: none;
  border: none;
}
.nalog-form select {
  border: 1px solid var(--black);
  border-radius: 15px;
  padding: 20px 30px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6.5 8L0.00480938 0.5L12.9952 0.5L6.5 8Z' fill='black'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 50%;
}

.nalog-form-input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.nalog-form-btn {
  width: 100%;
  background-color: var(--black);
  color: var(--white);
  padding: 20px 35px;
  border: 1px solid var(--black);
  outline: none;
  font-size: 20px;
  font-family: JuraSemiBold;
  font-weight: 600;
  border-radius: 15px;
  height: max-content;
  place-self: center;
  transition: 0.5s;
  text-decoration: none;
}

.nalog-form p {
  width: 100%;
  color: var(--black);
  font-size: 18px;
  line-height: 1.2;
}

.nalog-form-error {
  position: absolute;
  color: var(--error);
  background-color: var(--white);
  padding: 8px 10px;
  border-radius: 2px;
  z-index: 1;
  margin-top: 5px;
  left: 0;
  top: 100%;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.2);
  font-size: 22px;
  opacity: 1;
  transition: 0.5s;
}

.nalog-form-error::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -12px;
  left: 15%;
  border: 6px solid transparent;
  border-bottom-color: #fff;
}

@media screen and (max-width: 1500px) {
  .nalog-form {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1200px) {
  .nalog-form-title {
    display: none;
  }
  .nalog-form-block:not(:last-of-type),
  .nalog-form select {
    padding: 15px 30px;
  }
  .nalog-form-block:last-of-type {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
  .nalog-form-btn {
    padding: 20px 25px;
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .nalog-form-title {
    display: block;
    text-align: center;
    font-size: 30px;
  }
  .nalog-form__wrapper {
    width: calc(100% - 30px);
    padding: 40px 25px;
  }
  .nalog-form__wrapper p {
    display: none;
  }
  .nalog-form-input,
  .nalog-form-block .select-code {
    font-size: 16px;
  }
  .nalog-form {
    gap: 10px;
  }
  .nalog-form-block:not(:last-of-type) {
    background-color: var(--white);
    padding: 15px 20px;
  }
  .nalog-form select {
    background-color: var(--white);
    padding: 15px 20px;
  }
  .nalog-form-block:last-of-type {
    gap: 15px;
    text-align: left;
  }
  .nalog-form-block:last-of-type p {
    font-size: 16px;
    color: var(--white);
  }
  .nalog-form-error {
    font-size: 16px;
  }
  .nalog-form-btn {
    padding: 15px 20px;
    font-size: 14px;
  }
}
</style>
