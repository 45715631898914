<template>
  <div class="bankrupt-popup" :class="$store.state.openBankrupt ? 'open' : ''">
    <div class="back-btn" @click="close">
      <div class="container">
        <svg
          role="presentation"
          class="t-popup__close-icon"
          width="11"
          height="20"
          viewBox="0 0 11 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L10 10L1 19" stroke="#000000" stroke-width="2"></path>
        </svg>
        Дела
        <div></div>
      </div>
    </div>
    <div class="bankrupt-popup-body container">
      <span>№ {{ popup?.number }}</span>
      <p v-html="popup?.text_popup">
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    popup: {
      number: "",
      text_popup: "",
      link: "",
    },
  },
  methods: {
    close() {
      this.$store.commit("openBankrupt", false);
    },
  },
};
</script>
<style>
.bankrupt-popup {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100lvh;
  background: var(--white);
  z-index: 150;
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;
}

.bankrupt-popup.open {
  opacity: 1;
  pointer-events: all;
}

.back-btn {
  width: 100%;
  padding: 25px 0px;
  border-bottom: 1px solid var(--black);
}

.back-btn .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.back-btn .container svg {
  transform: rotate(180deg);
  cursor: pointer;
}

.container.bankrupt-popup-body {
  width: 760px;
  padding-top: 50px;
  opacity: 0;
  transform: translateY(100%);
  transition: 0.8s;
}

.bankrupt-popup.open .container.bankrupt-popup-body {
  transform: translateY(0%);
  opacity: 1;
}

.bankrupt-popup-body span {
  display: block;
  font-size: 36px;
  line-height: 1.23;
  margin-bottom: 50px;
}

.bankrupt-popup-body p {
  font-size: 20px;
  line-height: 1.55;
}

.bankrupt-popup-body a {
  text-decoration: none;
  font-size: 20px;
  line-height: 1.55;
  font-weight: 800;
  color: #ff8562;
}

@media screen and (max-width: 1200px) {
  .container.bankrupt-popup-body {
    width: 100%;
  }

  .bankrupt-popup-body span {
    margin-bottom: 20px;
    font-size: 30px;
  }

  .bankrupt-popup-body p {
    font-size: 16px;
  }

  .bankrupt-popup-body a {
    font-size: 16px;
  }
}
</style>
