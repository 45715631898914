<template>
  <section id="experience">
    <div class="container experience__wrapper">
      <div class="experience-content">
        <div class="experience-header">
          <h2 class="experience-body" v-html="experience?.title"></h2>
          <div class="experience-block">
            <div
              class="experience-subtitle"
              v-html="experience?.subtitle"
            ></div>
            <a href="/case" class="experience-btn">Портфолио</a>
            <div class="consulting-list">
              <span>Познакомьтесь с перечнем услуг</span>
              <a href="#main_services">Все услуги</a>
              <button class="consulting-btn" @click="openPopup">
                Оставить заявку
              </button>
            </div>
          </div>
        </div>
        <div class="experience-footer">
          <div class="experience-item" v-for="item in experience?.list">
            <div class="experience-item-title">{{ item.title }}</div>
            <div class="experience-item-text">{{ item.subtitle }}</div>
          </div>
          <a href="/case" class="experience-btn">Портфолио</a>
        </div>
        <img
          class="experience-footer-decor"
          :src="require('@/assets/img/experience-mobile.svg')"
          alt=""
        />
      </div>
      <div class="experience-img">
        <img :src="experience?.img" alt="" />
      </div>
      <div class="experience-decor">
        <img :src="require('@/assets/img/svg/experience.svg')" alt="" />
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    experience: {
      title: "",
      subtitle: "",
      list: [],
      img: "",
    },
  },
  methods: {
    openPopup() {
      this.$store.commit("openPopup", true);
      const body = document.querySelector("body");
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      const menu = document.querySelector(".header-container");
      menu.style.paddingRight = `${scrollbarWidth / 2 + 15}px`;
      body.style.paddingRight = `${scrollbarWidth}px`;
      body.classList.add("hidden");
    },
  },
};
</script>
<style>
#experience {
  background-position: center center;
  background-attachment: scroll;
  background-image: url(../../assets/img/experience-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  /* position: relative;
  height: 100vh;
  overflow: hidden;
  min-width: 100vw;
  flex: 0 0 auto; */
}
.container.experience__wrapper {
  padding: 80px 20px 100px;
}
.experience-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 550px;
  gap: 30px;
  height: 100%;
}
.experience-header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
  margin-left: 80px;
}
.experience-body {
  /* margin-top: 160px; */
  max-width: 700px;
  font-size: 80px;
  font-family: JuraRegular;
  font-weight: 500;
  display: inline-flex;
  flex-direction: column;
  line-height: normal;
}
.experience-body span {
  font-weight: 700;
  color: var(--white);
  margin-top: -20px;
}
.experience-subtitle {
  color: var(--white);
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;
}
.experience-footer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 90%;
  gap: 30px;
  background-color: var(--white);
  border-radius: 30px;
  padding: 50px 130px 80px 80px;
  width: 100%;
}
.experience-block a {
  display: none;
}
.experience-footer a {
  position: absolute;
  top: 100%;
  translate: 0 -50%;
  left: 80px;
  background-color: #262626;
  color: var(--white);
  padding: 20px 70px;
  border: none;
  outline: none;
  font-size: 20px;
  font-family: JuraSemiBold;
  font-weight: 600;
  border-radius: 15px;
  height: max-content;
  place-self: center;
  transition: 0.5s;
  text-decoration: none;
}
.experience-footer a:hover {
  background-color: var(--white);
  color: var(--black);
}

.experience-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.experience-item-title {
  color: var(--dark);
  font-size: 60px;
  line-height: 0.8;
  font-weight: 900;
}
.experience-item-text {
  color: var(--dark);
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
}
.experience-item:not(:last-of-type) .experience-item-text {
  margin-left: 35px;
}

.experience-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: auto;
  height: 100%;
}
.experience-img img {
  width: 100%;
  height: 100%;
  display: block;
}
.experience-decor {
  position: absolute;
  top: -50%;
  right: -40%;
  rotate: 180deg;
  z-index: -2;
}
.experience-decor img {
  width: 100%;
  height: 100%;
  display: block;
}
.experience-footer ul.experience-cities {
  padding: 0;
}
.experience-footer ul.experience-cities li::marker {
  content: "";
}
.experience-footer-decor {
  display: none;
}
.experience-block .consulting-list {
  display: none;
}

@media screen and (max-width: 1400px) {
  /* .experience-img {
    width: 400px;
  } */
}

@media screen and (max-width: 1200px) {
  #experience {
    background: var(--white);
    overflow: visible;
    overflow-x: clip;
  }
  .experience-body {
    margin: 0;
  }
  .experience-body span {
    color: var(--black);
    font-size: 36px;
    margin-top: -5px;
    letter-spacing: 2px;
    font-family: JuraBold;
  }
  .experience-header {
    flex-direction: row;
    align-items: center;
    gap: 60px;
    margin: 0;
  }
  .experience-img {
    display: none;
  }
  .experience-content {
    position: static;
    margin: 0;
  }
  .container.experience__wrapper {
    padding: 150px 50px 30px;
  }
  .experience-subtitle {
    color: var(--black);
    font-size: 14px;
  }
  .experience-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .experience-block a {
    display: block;
    background-color: #262626;
    color: var(--white);
    padding: 15px 50px;
    border: none;
    outline: none;
    font-size: 20px;
    font-family: JuraSemiBold;
    font-weight: 600;
    border-radius: 15px;
    height: max-content;
    width: max-content;
    transition: 0.5s;
    text-decoration: none;
  }
  .experience-block a:hover {
    background-color: var(--white);
    color: var(--black);
  }
  .experience-footer {
    position: absolute;
    top: 0;
    background-color: #ebebeb;
    translate: 0 -50%;
    z-index: 1;
    margin-left: -30px;
    max-width: calc(100% - 40px);
    padding: 50px 70px;
  }
  .experience-footer a {
    display: none;
  }
  .experience-decor {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .container.experience__wrapper {
    padding: 300px 15px 0;
  }

  .experience-body span {
    font-size: 30px;
    margin-top: -10px;
  }

  .experience-footer {
    display: flex;
    flex-direction: column;
    max-width: none;
    margin: 0;
    background-color: transparent;
    padding: 0 30px;
  }
  .experience-header {
    flex-direction: column;
    gap: 30px;
  }
  .experience-footer-decor {
    position: absolute;
    display: block;
    pointer-events: none;
    top: 0;
    left: -50%;
    width: 700px;
    height: 800px;
    z-index: 0;
    translate: 0 -50%;
    rotate: -10deg;
  }
  .experience-footer a {
    position: static;
    display: block;
    translate: 0;
    place-self: start;
    font-size: 16px;
    padding: 15px 30px;
  }
  .experience-item .experience-item-text,
  .experience-item:not(:last-of-type) .experience-item-text {
    margin: 0;
    font-size: 12px;
  }
  .experience-item-title {
    font-size: 70px;
  }
  .experience-block a {
    display: none;
  }
  .experience-block .consulting-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 10px;
    background-color: #ebebeb;
  }
  .experience-block .consulting-list span {
    display: none;
  }
  .experience-block .consulting-list a {
    display: block;
    width: 100%;
    text-align: center;
    background-color: transparent;
    border: 1px solid var(--black);
    color: var(--black);
    font-size: 16px;
  }
  .experience-block .consulting-list .consulting-btn {
    width: 100%;
    padding: 15px 50px;
    font-size: 16px;
    border-radius: 15px;
    background-color: var(--black);
  }
}

@media screen and (max-height: 790px) and (min-width: 1200px) {
  .container.experience__wrapper {
    padding: 50px 20px 100px;
  }

  .experience-body {
    font-size: 68px;
  }

  .experience-header {
    gap: 30px;
  }

  .experience-item-title {
    font-size: 52px;
  }

  .experience-footer {
    padding: 30px 80px 40px 50px;
  }

  .experience-content {
    gap: 20px;
    width: fit-content;
    margin-left: 420px;
  }

  .experience-footer a {
    font-size: 18px;
    padding: 15px 55px;
  }

  .container.experience__wrapper {
    padding-top: 30px;
  }

  .experience-subtitle {
    font-size: 16px;
  }

  .experience-img {
    width: max-content;
  }
}
</style>
